import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Form } from 'react-bootstrap'

export const CollegiatsFilter = ({ handleSearch, formValuesSearch, handleInputChangeSearch, fetchParametersData }) => {

    return (
        <div className='col-xl-3'>
            <div className='Column-small shadow-sm sticky-top'>

                <h5>Filtre</h5>
                <Form className='row' onSubmit={handleSearch}>
                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Núm. Col·legiat</Form.Label>
                        <Form.Control type="text" autoComplete="off" name='num_colegiado' value={formValuesSearch.num_colegiado} onChange={handleInputChangeSearch} />
                    </Form.Group>

                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>NIF</Form.Label>
                        <Form.Control type="text" autoComplete="off" name='nif' value={formValuesSearch.nif} onChange={handleInputChangeSearch} />
                    </Form.Group>

                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label>Col·legi</Form.Label>
                        <Form.Select aria-label="Default select example"
                            value={formValuesSearch.colegio_id}
                            name='colegio_id'
                            onChange={handleInputChangeSearch}>
                            <option></option>
                            {fetchParametersData?.data?.data?.colegios?.map((item) => {
                                return (
                                    <option key={item.id} value={item.id}>{item.nombre}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>

                    {/* <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>ID de l'Enginyer</Form.Label>
                        <Form.Control type="text" autoComplete="off" name='search_text' value={formValuesSearch.search_text} onChange={handleInputChangeSearch} />
                    </Form.Group> */}

                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label>Estat</Form.Label>
                        <Form.Select aria-label="Default select example" value={formValuesSearch.estado_id} name='estado_id' onChange={handleInputChangeSearch}>
                            <option></option>
                            {fetchParametersData?.data?.data?.estados_colegiacion?.map((item) => {
                                return (
                                    <option key={item.id} value={item.id}>{item.nombre}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>

                    <div className='mt-2'>
                        <button className="px-3 btn btn-primary float-end">
                            <FontAwesomeIcon icon={faSearch} /> Cercar
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    )
}
