import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Collegiats } from '../pages/Collegiats/Collegiats';
import { Collegis } from '../pages/Collegis/Collegis';
import { Usuaris } from '../pages/Usuaris/Usuaris';
import { Logs } from '../pages/Logs/Logs';

import { PrivateRouteSuperAdmin } from './PrivateRouteSuperAdmin';
import { AuthContext } from '../auth/AuthContext';
import { LayoutNavs } from '../components/LayoutNavs/LayoutNavs';

export const DashboardRoutes = () => {

    const { user } = useContext(AuthContext);

    return (
        <>
            <LayoutNavs />

            <div className="Side-end container-fluid">
                <Switch>
                    <Route
                        // exact
                        path="/collegiats"
                        component={() => <Collegiats namePage='Col·legiats' />}
                    />

                    <PrivateRouteSuperAdmin
                        exact
                        path="/collegis"
                        component={() => <Collegis namePage='Col·legis' />}
                        isSuperAdmin={user.perfil.nombre === 'Administrador'}
                    />

                    <PrivateRouteSuperAdmin
                        exact
                        path="/usuaris"
                        component={() => <Usuaris namePage='Usuaris' />}
                        isSuperAdmin={user.perfil.nombre === 'Administrador'}
                        isAdminColegi={user.perfil.id === 'administrador_colegio'}
                    />

                    <PrivateRouteSuperAdmin
                        exact
                        path="/logs"
                        component={() => <Logs namePage='Logs' />}
                        isSuperAdmin={user.perfil.nombre === 'Administrador'}
                    />

                    <Redirect to="/collegiats" />
                </Switch>
            </div>

        </>
    )
}
