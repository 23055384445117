import React, { useEffect } from 'react'
import { Table, Breadcrumb } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

import './Logs.css'
import { useFetch } from '../../hooks/useFetch';
import { Loader } from '../../components/Loader/Loader';

export const Logs = ({ namePage }) => {

    const [fetchLogsLaravel, fetchLogsLaravelData] = useFetch('GET', `${process.env.REACT_APP_API_URL}/logs/laravel`, null, 'text')

    const [fetchLogsRequest, fetchLogsRequestData] = useFetch('GET', `${process.env.REACT_APP_API_URL}/logs/requests`, null, 'text')

    useEffect(() => {

        fetchLogsLaravel({
            params: {
                n_lines: 100
            }
        })
        fetchLogsRequest({
            params: {
                n_lines: 100
            }
        })

    }, [])

    return (
        <div className="container-inner p-3 p-md-4 row justify-content-between">
            <Breadcrumb className="Breadcrumb-inner mt-lg-3">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {namePage}
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className='col-lg-6 mb-4'>
                <div className="MainInner shadow-sm animate__animated animate__fadeInUp ">
                    <div className="d-flex justify-content-between mb-3">
                        <div>
                            <h5>Llista de Logs Laravel</h5>
                        </div>
                        {/* <Button variant="primary" onClick={handleShow} className="px-3 ms-3 d-flex align-items-center">
                            <FontAwesomeIcon icon={faSpinner} className='me-2' /> Actualitzar
                        </Button> */}
                    </div>
                    {
                        fetchLogsLaravelData?.loading ?
                            <Loader /> :

                            <>
                                <Table hover bordered responsive className=''>
                                    <thead>
                                        <tr>
                                            <th>Log</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fetchLogsLaravelData?.data ? <>
                                            {
                                                fetchLogsLaravelData?.data.split('[').map((item, index) => {
                                                    return (
                                                        item ? <tr key={index}><td>{'[' + item}</td></tr> : null
                                                    )
                                                })
                                            }
                                        </> : null}
                                    </tbody>
                                </Table>
                            </>
                    }
                </div>
            </div>

            <div className='col-lg-6 mb-4'>
                <div className="MainInner shadow-sm animate__animated animate__fadeInUp">
                    <div className="d-flex justify-content-between mb-3">
                        <div>
                            <h5>Llista de Requests</h5>
                        </div>
                        {/* <Button variant="primary" onClick={handleShow} className="px-3 ms-3 d-flex align-items-center">
                            <FontAwesomeIcon icon={faSpinner} className='me-2' /> Actualitzar
                        </Button> */}
                    </div>
                    {
                        fetchLogsRequestData?.loading ?
                            <Loader /> :
                            <>
                                <Table hover bordered responsive className=''>
                                    <thead>
                                        <tr>
                                            <th>Log</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fetchLogsRequestData?.data ? <>
                                            {
                                                fetchLogsRequestData?.data.split('[').map((item, index) => {
                                                    return (
                                                        item ? <tr key={index}><td>{'[' + item}</td></tr> : null
                                                    )
                                                })
                                            }
                                        </> : null}
                                    </tbody>
                                </Table>
                            </>
                    }
                </div>
            </div>
        </div>

    )
}
