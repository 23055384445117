import React from 'react'
import { Form } from 'react-bootstrap'

export const MsgInputServer = ({ name, obj }) => {

    return (
        <>
            {
                obj?.status === 'Error' && obj?.data?.[name]?.map((elem, index) => {
                    return (
                        <Form.Text key={index} className='text-danger d-block'>{elem}</Form.Text>
                    )
                })
            }
        </>

    )
}
