import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUniversity, faUserGraduate, faUserCog, faListUl } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from '../../auth/AuthContext';

export const NavLinksInner = () => {

    const { user: { perfil } } = useContext(AuthContext);

    return (
        <div className="py-4 me-4">
            <NavLink
                activeClassName="active"
                className="d-flex my-3 nav-link"
                exact
                to="/collegiats"
            >
                <div style={{ 'width': '2rem' }} className="me-2">
                    <FontAwesomeIcon icon={faUserGraduate} />
                </div>
                Col·legiats
            </NavLink>
            {
                perfil.nombre === 'Administrador' ?
                    <NavLink
                        activeClassName="active"
                        className="d-flex my-3 nav-link"
                        exact
                        to="/collegis"
                    >
                        <div style={{ 'width': '2rem' }} className="me-2">
                            <FontAwesomeIcon icon={faUniversity} />
                        </div>
                        Col·legis
                    </NavLink>
                    : null
            }
            {
                perfil.nombre === 'Administrador' || perfil.id === 'administrador_colegio' ?
                    <NavLink
                        activeClassName="active"
                        className="d-flex my-3 nav-link"
                        exact
                        to="/usuaris"
                    >
                        <div style={{ 'width': '2rem' }} className="me-2">
                            <FontAwesomeIcon icon={faUserCog} />
                        </div>
                        Usuaris
                    </NavLink>
                    : null
            }
            {
                perfil.nombre === 'Administrador' ?
                    <NavLink
                        activeClassName="active"
                        className="d-flex my-3 nav-link"
                        exact
                        to="/logs"
                    >
                        <div style={{ 'width': '2rem' }} className="me-2">
                            <FontAwesomeIcon icon={faListUl} />
                        </div>
                        Logs
                    </NavLink>
                    : null
            }
        </div>
    )
}
