import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { Button, Form, Modal, Spinner, Table } from 'react-bootstrap'
import { getMsgInputColor, getMsgInputServerColor } from '../../helpers/helpers'
import { Loader } from '../Loader/Loader'
import { MsgInput } from '../MsgInput/MsgInput'
import { MsgInputServer } from '../MsgInput/MsgInputServer'
import './CollegiatsMain.css'

export const ModalDarDeBaja = ({
    showModalDarDeBaja,
    handleCloseModalDarDeBaja,
    fetchParametersData,
    formValuesDarDeBaja,
    fetchDarDeBaja,
    fetchDarDeBajaData,
    formValuesSearch,
    fetchCollegiats,
    handleInputChangeDarDeBaja,
    valuesValidDarDeBaja,
    handleVerifiedValuesDarDeBaja,
    handleAreAllValuesCorrectDarDeBaja }) => {

    const handleClickDarDeBaja = (e) => {
        e.preventDefault()

        handleVerifiedValuesDarDeBaja(formValuesDarDeBaja)

        if (handleAreAllValuesCorrectDarDeBaja()) {
            fetchDarDeBaja({
                body: {
                    ...formValuesDarDeBaja
                }
            })
        }

    }

    // useEffect(() => {
    //     console.log(formValuesDarDeBaja)
    // }, [formValuesDarDeBaja])

    useEffect(() => {

        if (fetchDarDeBajaData.data?.status === 'Success') {

            handleCloseModalDarDeBaja()

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchCollegiats({
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }

    }, [fetchDarDeBajaData])

    return (
        <Modal show={showModalDarDeBaja} onHide={handleCloseModalDarDeBaja} size="lg" id="ModalDarDeBaja">
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Fitxa de baixa</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-4'>

                <div className="row">
                    <div className="col-12">
                        <div className='mt-1 mb-3 text-center'>
                            <h5 className='my-0'>
                                {formValuesDarDeBaja.nif} <span className='fw-light'>-</span> {formValuesDarDeBaja.nombre + ' ' + formValuesDarDeBaja.apellidos} <span className='fw-light'>-</span> {formValuesDarDeBaja.num_colegiado}
                            </h5>
                            <div className='mt-1'>
                                {fetchParametersData?.data?.data?.colegios?.map((item) => {
                                    if (item.id !== formValuesDarDeBaja.colegio_id) {
                                        return null
                                    }
                                    return (
                                        <p className='mb-0 fw-normal' key={item.id}>{item.nombre}</p>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <Form.Group className="col-12 mb-4 d-none" controlId="exampleForm.ControlInput4">
                        <Form.Label className='mb-3 col-12 d-block'>
                            Tipus de baixa
                        </Form.Label>
                        <div className="d-flex flex-wrap">
                            {fetchParametersData?.data?.data?.estados_colegiacion?.map((item) => {
                                if (item.tipo === 'alta') {
                                    return null
                                }
                                return (
                                    <div className='flex-grow-1 my-2 my-md-0' key={item.id}>
                                        <Form.Check
                                            inline
                                            label={`${item.nombre}`}
                                            type='radio'
                                            id={`inline-radio-${item.id}`}
                                            defaultChecked={item.id === 'baja'}
                                            onChange={handleInputChangeDarDeBaja}
                                            name='estado_id'
                                            value={`${item.id}`}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </Form.Group>
                    
                    <Form.Group className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-4" controlId="exampleForm.ControlInput4">
                        <Form.Label>
                            Data de baixa
                        </Form.Label>
                        <Form.Control
                            type="date"
                            onChange={handleInputChangeDarDeBaja}
                            value={formValuesDarDeBaja.fecha_estado}
                            name='fecha_estado'
                            autoComplete="one-time-code"
                            className={`${getMsgInputServerColor(fetchDarDeBajaData, 'fecha_estado') || getMsgInputColor(valuesValidDarDeBaja, 'fecha_estado') ? 'is-invalid' : ''}`}
                        />
                        <MsgInput obj={valuesValidDarDeBaja} name={'fecha_estado'} />
                        <MsgInputServer obj={fetchDarDeBajaData?.data} name={'fecha_estado'} />
                    </Form.Group>

                    <Form.Group className="col-12 mb-4" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Observacions (opcional)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            onChange={handleInputChangeDarDeBaja}
                            value={formValuesDarDeBaja.observaciones}
                            name='observaciones'
                            autoComplete="one-time-code"
                        />
                    </Form.Group>

                </div>

            </Modal.Body>
            <Modal.Footer>

                <Button variant="secondary" onClick={handleCloseModalDarDeBaja}>
                    Sortir
                </Button>
                <Button
                    variant="danger"
                    type='submit'
                    className='ms-2'
                    disabled={fetchDarDeBajaData.loading}
                    onClick={handleClickDarDeBaja}
                >
                    {
                        fetchDarDeBajaData.loading ?
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className='me-2'
                            />
                            : ''
                    }
                    <FontAwesomeIcon icon={faArrowDown} className='me-1' /> Donar de baixa
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
