import React from 'react';

import '../AvisLegal/AvisLegal.css'

import { NavTopLegal } from '../../components/NavTopLegal/NavTopLegal';

export const PoliticaPrivacitat = () => {

    return (
        <div className="App bg-opacity-primary">
            <section className="App-header">
            <NavTopLegal />
                <section id="Main-legal">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="Main-legal-content shadow-sm animate__animated animate__fadeInUp">
                                    <h4 className="text-center">Política de privacitat</h4>

                                    <h5 className="mb-4 mb-md-4 fw-bold h5 mt-0">A nivell operatiu es contemplen les següents àrees:</h5>

                                    <p>
                                        Per poder accedir a algun dels serveis del Lloc, que disposen de gestió o tràmit específic, els usuaris i les usuàries hauran d'aportar les dades personals imprescindibles per a la prestació de el servei sol·licitat.
                                    </p>
                                    <p>
                                        Aquestes dades de caràcter personal s'integren en les corresponents activitats de tractament d'Ares S.Coop. i seran tractades amb la finalitat específica de cada tractament de conformitat amb la regulació establerta pel Reglament (UE) 2016/679 de el Parlament Europeu i de Consell sobre protecció de dades de caràcter personal i les mesures de seguretat requerides per la normativa legal, especialment per l'Esquema Nacional de Seguretat, tenint en compte la tipologia de les dades i els riscos previsibles.
                                    </p>
                                    <p>
                                        Exercici de drets d'accés, cancel·lació, rectificació o oposició, previstos a la Llei, l'interessat ha de:
                                    </p>
                                    <ul>
                                        <li>
                                            Dirigir-se a: Ares S.Coop. Carrer Covarrubias 22, 3r Dreta 28010 Madrid, indicant clarament en l'assumpte: Tutela de Drets.
                                        </li>
                                        <li>
                                            Comunicar per correu electrònic a l'adreça info@arescoop.es, indicant en l'assumpte: Tutela de Drets
                                        </li>
                                    </ul>
                                    <p>
                                        A continuació es faciliten uns formularis subministrats per L'Agència Espanyola de Protecció de Dades de drets relatius a política de privacitat que pot exercir.
                                    </p>
                                    <p>
                                        <strong>DRET D'ACCÉS: </strong> Ares facilitarà als interessats còpia de les dades personals de què es disposi juntament amb la finalitat per a la qual han estat recollides, la identitat dels destinataris de les dades, els terminis de conservació prevists o el criteri utilitzat per determinar- , l'existència d'el dret a sol·licitar la rectificació o supressió de dades personals així com la limitació o l'oposició al seu tractament, el dret a presentar una reclamació davant l'Agència Espanyola de Protecció de dades i si les dades no han estat obtinguts de l'interessat, qualsevol informació disponibles sobre el seu origen. El dret a obtenir còpia de les dades no pot afectar negativament els drets i llibertats d'altres interessats.
                                    </p>
                                    <ul>
                                        <li>
                                            <a href="https://www.aepd.es/media/formularios/formulario-derecho-de-acceso.pdf" target="_blank" rel="noopener noreferrer">Formulari per al'exercici de el dret d'accés.</a>
                                        </li>
                                    </ul>
                                    <p>
                                        <strong>DRET DE RECTIFICACIÓ: </strong> En el dret de rectificació es procedirà a modificar les dades dels interessats que fossin inexactes o incompletes atenent els fins de l'tractament. L'interessat haurà d'indicar a la sol·licitud a quines dades es refereix i la correcció que s'ha de realitzar, aportant, quan calgui, la documentació justificativa de la inexactitud o caràcter incomplet de les dades objecte de tractament. Si les dades han estat comunicats pel responsable a altres responsables, ha de notificar-los la rectificació d'aquests llevat que sigui impossible o exigeixi un esforç desproporcionat, facilitant a l'interessat informació sobre aquests destinataris, si així ho sol·licita.
                                    </p>
                                    <ul>
                                        <li>
                                            <a href="https://www.aepd.es/media/formularios/formulario-derecho-de-rectificacion.pdf" target="_blank" rel="noopener noreferrer">Formulari per al'exercici de el dret de rectificació</a>
                                        </li>
                                    </ul>
                                    <p>
                                        <strong>DRET DE SUPRESSIÓ: </strong> En el dret de supressió s'eliminaran les dades dels interessats quan aquests manifesten la seva negativa a el tractament i no hi hagi una base legal que ho impedeixi, no siguin necessaris en relació amb els fins per als quals van ser recollits, retirin el consentiment prestat i no hi hagi una altra base legal que legitimi el tractament o aquest sigui il·lícit. Si la supressió deriva de l'exercici de el dret d'oposició de l'interessat a l'tractament de les seves dades amb fins de màrqueting, poden conservar les dades identificatives de la persona interessada per tal d'impedir futurs tractaments. Si les dades han estat comunicats pel responsable a altres responsables, ha de notificar-los la supressió d'aquests llevat que sigui impossible o exigeixi un esforç desproporcionat, facilitant a l'interessat informació sobre aquests destinataris, si així ho sol·licita.
                                    </p>
                                    <ul>
                                        <li>
                                            <a href="https://www.aepd.es/media/formularios/formulario-derecho-de-supresion.pdf" target="_blank" rel="noopener noreferrer">Formulari per al'exercici de el dret de supressió</a>
                                        </li>
                                    </ul>
                                    <p>
                                        <strong>DRET D'OPOSICIÓ: </strong> En el dret d'oposició, quan els interessats manifestin la seva negativa a el tractament de les seves dades personals davant el responsable, aquest deixarà de processar-sempre que no hi hagi una obligació legal que ho impedeixi. Quan el tractament estigui basat en una missió d'interès públic o en l'interès legítim de l'responsable, davant una sol·licitud d'exercici de el dret d'oposició, el responsable deixarà de tractar les dades tret que s'acreditin motius imperiosos que prevalguin sobre els interessos, drets i llibertats de l'interessat o siguin necessaris per a la formulació, exercici o defensa de reclamacions. Si l'interessat s'oposa a el tractament amb fins de màrqueting directe, les dades personals deixaran de ser tractats per aquests fins.
                                    </p>
                                    <ul>
                                        <li>
                                            <a href="https://www.aepd.es/media/formularios/formulario-derecho-de-oposicion.pdf" target="_blank" rel="noopener noreferrer">Formulari per al'exercici de el dret d'oposició.</a>
                                        </li>
                                    </ul>
                                    <p>
                                        <strong>DRET DE PORTABILITAT: </strong> En el dret de portabilitat, si el tractament s'efectua per mitjans automatitzats i es basa en el consentiment o es realitza en el marc d'un contracte, els interessats poden sol·licitar rebre còpia de les seves dades personals en un format estructurat, de ús comú i lectura mecànica. Així mateix, tenen dret a sol·licitar que siguin transmesos directament a un nou responsable, la identitat s'ha de comunicar, quan sigui tècnicament possible.
                                    </p>
                                    <ul>
                                        <li>
                                            <a href="https://www.aepd.es/media/formularios/formulario-derecho-de-portabilidad.pdf" target="_blank" rel="noopener noreferrer">Formulari per a l'exercici de la portabilitat de les dades.</a>
                                        </li>
                                    </ul>
                                    <p>
                                        <strong>DRET D'ALTRES A EL TRACTAMENT: </strong> En el dret de limitació de l'tractament, els interessats poden sol·licitar la suspensió de l'tractament de les seves dades per impugnar la seva exactitud mentre el responsable realitza les verificacions necessàries o en el cas que el tractament es realitzi en base a l'interès legítim de l'responsable o en compliment d'una missió d'interès públic, mentre es verifica si aquests motius prevalen sobre els interessos, drets i llibertats l'interessat. L'interessat també pot sol·licitar la conservació de les dades si considera que el tractament és il·lícit i, en lloc de la supressió, sol·licita la limitació de l'tractament, o si encara no necessitant ja el responsable per als fins per als quals van ser recollides, l'interessat els necessita per a la formulació, exercici o defensa de reclamacions. La circumstància que el tractament de les dades de l'interessat estigui limitat ha de constar clarament en els sistemes de l'responsable. Si les dades han estat comunicats pel responsable a altres responsables, ha de notificar-los la limitació de l'tractament d'aquests llevat que sigui impossible o exigeixi un esforç desproporcionat, facilitant a l'interessat informació sobre aquests destinataris, si així ho sol·licita.
                                    </p>
                                    <ul>
                                        <li>
                                            <a href="https://www.aepd.es/media/formularios/formulario-derecho-de-limitacion.pdf" target="_blank" rel="noopener noreferrer">Formulari per a l'exercici de la limitació de l'tractament.</a>
                                        </li>
                                    </ul>
                                    <p>
                                        Si no es dóna curs a la sol·licitud de l'interessat, el responsable de l'tractament li informarà, sense dilació i com a màxim transcorregut un mes des de la recepció d'aquesta, de les raons de la no actuació i de la possibilitat de presentar una reclamació davant l'Agència espanyola de Protecció de Dades i d'exercitar accions judicials.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container pt-4">
                        <div className="text-center">
                            2021 © Consell d'Enginyers Graduats i Enginyers Tècnics Industrials de Catalunya
                        </div>
                    </div>
                </section>
            </section>
        </div>
    )
}
