import { faArrowDown, faArrowRight, faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, Modal, Spinner, Table } from 'react-bootstrap'
import { getMsgInputColor, getMsgInputServerColor } from '../../helpers/helpers'
import { useFetch } from '../../hooks/useFetch'
import { useValidator } from '../../hooks/useValidator'
import { Loader } from '../Loader/Loader'
import { MsgInput } from '../MsgInput/MsgInput'
import { MsgInputServer } from '../MsgInput/MsgInputServer'
import './CollegiatsMain.css'

export const ModalDarDeAlta = ({
    showModalDarDeAlta,
    setShowModalDarDeAlta,
    handleCloseModalDarDeAlta,
    fetchParametersData,
    handleInputChangeDarDeAlta,
    handleFormChangeDarDeAlta,
    formValuesDarDeAlta,
    resetFormValuesDarDeAlta,
    fetchMapaColegiosData,
    fetchMapaColegios,
    existeNIF,
    setExisteNIF,
    existeNIFColegio,
    setExisteNIFColegio,
    fetchDarDeAltaExisteNIF,
    fetchDarDeAltaExisteNIFData,
    fetchDarDeAltaExisteNIFColegio,
    fetchDarDeAltaExisteNIFColegioData,
    fetchDarDeAltaCrearIngeniero,
    fetchDarDeAltaCrearIngenieroData,
    fetchDarDeAltaCrearColegiado,
    fetchDarDeAltaCrearColegiadoData,
    colegio,
    setExisteNIFColegioBaja,
    existeNIFColegioBaja,
    formValuesSearch,
    fetchCollegiats,

    valuesValidNIF,
    handleVerifiedValuesNIF,
    handleAreAllValuesCorrectNIF,

    valuesValidNoExisteNIF,
    handleVerifiedValuesNoExisteNIF,
    handleAreAllValuesCorrectNoExisteNIF,

    valuesValidActivar,
    handleVerifiedValuesActivar,
    handleAreAllValuesCorrectActivar,

    valuesValidCrearColegiado,
    handleVerifiedValuesCrearColegiado,
    handleAreAllValuesCorrectCrearColegiado

}) => {

    const [changeAlta, setChangeAlta] = useState()
    const [showColegiacionesActuales, setShowColegiacionesActuales] = useState(false)
    const [numColegiadoAct, setNumColegiadoAct] = useState('')

    const handleCloseModalDarDeAlta2 = () => {

        setChangeAlta(false)
        setIngenieroTemporal()
        setShowColegiacionesActuales(false)
        handleCloseModalDarDeAlta()
    }

    const handleClickDarDeAltaExisteNIF = () => {

        handleVerifiedValuesNIF(formValuesDarDeAlta)

        if (handleAreAllValuesCorrectNIF()) {

            fetchDarDeAltaExisteNIF({
                params: {
                    nif: formValuesDarDeAlta.nif
                }
            })
        }
    }

    // Comprobando si existe 1 coincidencia de la busqueda del NIF
    useEffect(() => {

        if (fetchDarDeAltaExisteNIFData.data) {

            const ingTemp = fetchDarDeAltaExisteNIFData?.data?.data?.data[0]

            if (ingTemp) {
                setExisteNIF('existe')
                handleFormChangeDarDeAlta({
                    ...formValuesDarDeAlta,
                    nif: ingTemp.nif,
                    nombre: ingTemp.nombre,
                    apellidos: ingTemp.apellidos,
                    ingeniero_id: ingTemp.id,
                })
            } else {
                setExisteNIF('noExiste')
            }
        }

    }, [fetchDarDeAltaExisteNIFData])

    // Si existe el Nif, comprobando si existe en el colegio

    useEffect(() => {

        if (existeNIF === 'existe' && existeNIFColegio === 'init' && !ingenieroTemporal) {

            fetchDarDeAltaExisteNIFColegio({
                params: {
                    nif: formValuesDarDeAlta.nif
                }
            })

        }

    }, [existeNIF])

    useEffect(() => {

        let colegioIdT = colegio?.id || 1

        if (fetchDarDeAltaExisteNIFColegioData.data) {

            const colegiacionesTemp = fetchDarDeAltaExisteNIFColegioData.data.data.data

            let index = -1;

            for (let i = 0; i < colegiacionesTemp.length; i++) {

                if (colegiacionesTemp[i].colegio.id === colegioIdT) {
                    index = i
                    break;
                }
            }

            if (index !== -1) {

                setExisteNIFColegio('existe')

                // Guardar el num_colegiado

                const colegiacionTemp = colegiacionesTemp[index]

                // Preguntar si esta de baja

                if (colegiacionTemp.estado_colegiacion.tipo === 'baja') {

                    handleFormChangeDarDeAlta({
                        // estado_id: colegiacionTemp.estado_colegiacion?.id,
                        estado_id: 'reingres_mateix',
                        ingeniero_id: colegiacionTemp.ingeniero?.id,
                        colegio_id: colegiacionTemp.colegio?.id,
                        nif: colegiacionTemp.ingeniero?.nif,
                        nombre: colegiacionTemp.ingeniero?.nombre,
                        apellidos: colegiacionTemp.ingeniero?.apellidos,
                        fecha_estado: '',
                        // observaciones: colegiacionTemp.observaciones,
                        observaciones: '',

                        calle: colegiacionTemp.direccion_profesional?.calle || '',
                        poblacion: colegiacionTemp.direccion_profesional?.municipio_id || '',
                        codigo_postal: colegiacionTemp.direccion_profesional?.codigo_postal || '',
                        coordenadas: colegiacionTemp.direccion_profesional?.coordenadas || '',
                        municipio_id: colegiacionTemp.direccion_profesional?.municipio_id || '',

                        num_colegiado: colegiacionTemp.num_colegiado
                    })

                    setExisteNIFColegioBaja('existe')

                } else {

                    setExisteNIFColegioBaja('noExiste')
                }

            } else {

                if (colegiacionesTemp.length > 0) {

                    const colegiacionesAct = colegiacionesTemp[0].colegiaciones_actuales

                    handleFormChangeDarDeAlta({
                        ...formValuesDarDeAlta,
                        colegiaciones_actuales: colegiacionesAct,
                        num_colegiado: colegiacionesAct[0].num_colegiado
                    })

                    let result = false
                    for (let i = 0; i < colegiacionesAct.length; i++) {
                        if (colegiacionesAct[i].estado_id === 'baja') {
                            result = true
                            break
                        }
                    }
                    setShowColegiacionesActuales(result)
                }

                setExisteNIFColegio('noExiste')
            }
        }

    }, [fetchDarDeAltaExisteNIFColegioData])

    // Crear ingeniero

    const [ingenieroTemporal, setIngenieroTemporal] = useState();

    const handleClickDarDeAltaCrearIngeniero = () => {

        handleVerifiedValuesNoExisteNIF(formValuesDarDeAlta)

        if (handleAreAllValuesCorrectNoExisteNIF()) {

            // fetchDarDeAltaCrearIngeniero({
            //     body: {
            //         nombre: formValuesDarDeAlta.nombre,
            //         apellidos: formValuesDarDeAlta.apellidos,
            //         nif: formValuesDarDeAlta.nif
            //     }
            // })

            setIngenieroTemporal({
                nombre: formValuesDarDeAlta.nombre,
                apellidos: formValuesDarDeAlta.apellidos,
                nif: formValuesDarDeAlta.nif
            })
        }
    }

    // useEffect(() => {

    //     if (fetchDarDeAltaCrearIngenieroData.data) {

    //         console.log('id_ing', fetchDarDeAltaCrearIngenieroData.data)

    //         setExisteNIFColegio('noExiste')
    //         setExisteNIF('existe')

    //         handleFormChangeDarDeAlta({
    //             ...formValuesDarDeAlta,
    //             ingeniero_id: fetchDarDeAltaCrearIngenieroData.data?.data?.id
    //         })
    //     }

    // }, [fetchDarDeAltaCrearIngenieroData])

    useEffect(() => {

        if (ingenieroTemporal) {

            setExisteNIFColegio('noExiste')
            setExisteNIF('existe')
        }

    }, [ingenieroTemporal])

    // Crear la colegiacion

    const handleClickDarDeAltaCrearColegiado = () => {

        handleVerifiedValuesCrearColegiado(formValuesDarDeAlta)

        if (handleAreAllValuesCorrectCrearColegiado()) {

            if (ingenieroTemporal) {

                fetchDarDeAltaCrearIngeniero({
                    body: {
                        nombre: ingenieroTemporal.nombre,
                        apellidos: ingenieroTemporal.apellidos,
                        nif: ingenieroTemporal.nif
                    }
                })

            } else {

                fetchDarDeAltaCrearColegiado({
                    body: {
                        estado_id: formValuesDarDeAlta.estado_id,
                        ingeniero_id: formValuesDarDeAlta.ingeniero_id,
                        colegio_id: formValuesDarDeAlta.colegio_id,
                        nif: formValuesDarDeAlta.nif,
                        nombre: formValuesDarDeAlta.nombre,
                        apellidos: formValuesDarDeAlta.apellidos,
                        fecha_estado: formValuesDarDeAlta.fecha_estado,
                        observaciones: formValuesDarDeAlta.observaciones,

                        calle: formValuesDarDeAlta.calle,
                        poblacion: formValuesDarDeAlta.poblacion,
                        codigo_postal: formValuesDarDeAlta.codigo_postal,
                        municipio_id: formValuesDarDeAlta.municipio_id,
                        coordenadas: formValuesDarDeAlta.coordenadas,
                        // -1 en caso exista ingeniero pero sin ninguna colegiacion, ya que el api pide que el num_colegiado sea numero
                        num_colegiado: formValuesDarDeAlta.num_colegiado || -1,
                    }
                })
            }
        }
    }

    // Crear la ingeniero que se almaceno temporalmente para la colegiacion

    useEffect(() => {

        if (fetchDarDeAltaCrearIngenieroData.data) {

            fetchDarDeAltaCrearColegiado({
                body: {
                    estado_id: formValuesDarDeAlta.estado_id,
                    ingeniero_id: fetchDarDeAltaCrearIngenieroData.data?.data?.id,
                    colegio_id: formValuesDarDeAlta.colegio_id,
                    nif: formValuesDarDeAlta.nif,
                    nombre: formValuesDarDeAlta.nombre,
                    apellidos: formValuesDarDeAlta.apellidos,
                    fecha_estado: formValuesDarDeAlta.fecha_estado,
                    observaciones: formValuesDarDeAlta.observaciones,

                    calle: formValuesDarDeAlta.calle,
                    poblacion: formValuesDarDeAlta.poblacion,
                    codigo_postal: formValuesDarDeAlta.codigo_postal,
                    municipio_id: formValuesDarDeAlta.municipio_id,
                    coordenadas: formValuesDarDeAlta.coordenadas,

                }
            })
        }

    }, [fetchDarDeAltaCrearIngenieroData])

    // Accion despues de intentar dar de alta a una colegiacion

    const [numColegiado, setnumColegiado] = useState(false);

    useEffect(() => {

        if (fetchDarDeAltaCrearColegiadoData.data?.status === 'Success') {

            handleCloseModalDarDeAlta2()

            setnumColegiado(fetchDarDeAltaCrearColegiadoData.data.data.num_colegiado)

            handleShowSuccess()

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchCollegiats({
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }

    }, [fetchDarDeAltaCrearColegiadoData])

    // Colegiado de baja a alta

    // Crear la colegiacion

    const handleClickDarDeAltaActivarColegiado = () => {

        handleVerifiedValuesActivar(formValuesDarDeAlta)

        if (handleAreAllValuesCorrectActivar()) {

            fetchDarDeAltaCrearColegiado({
                body: {
                    estado_id: formValuesDarDeAlta.estado_id,
                    ingeniero_id: formValuesDarDeAlta.ingeniero_id,
                    colegio_id: formValuesDarDeAlta.colegio_id,
                    nif: formValuesDarDeAlta.nif,
                    nombre: formValuesDarDeAlta.nombre,
                    apellidos: formValuesDarDeAlta.apellidos,
                    fecha_estado: formValuesDarDeAlta.fecha_estado,
                    observaciones: formValuesDarDeAlta.observaciones,

                    calle: formValuesDarDeAlta.calle,
                    poblacion: formValuesDarDeAlta.poblacion,
                    codigo_postal: formValuesDarDeAlta.codigo_postal,
                    municipio_id: formValuesDarDeAlta.municipio_id,
                    coordenadas: formValuesDarDeAlta.coordenadas,

                    num_colegiado: formValuesDarDeAlta.num_colegiado,
                }
            })
        }
    }

    // Municipios y codigos postales

    // const [fetchMapaColegios, fetchMapaColegiosData] = useFetch('GET', `${process.env.REACT_APP_API_URL}/mapa_colegios`)

    useEffect(() => {

        if (fetchMapaColegiosData.data?.data && fetchMapaColegiosData.data?.data?.length > 0) {

            const array = fetchMapaColegiosData?.data?.data
            // console.log(array)

            let municipio_idTemp = array[0].municipio?.id

            if (formValuesDarDeAlta.municipio_id) {

                for (let i = 0; i < array.length; i++) {
                    if (formValuesDarDeAlta.municipio_id == array[i].municipio?.id) {
                        municipio_idTemp = array[i].municipio?.id
                    }
                }
            }

            handleFormChangeDarDeAlta({
                ...formValuesDarDeAlta,
                municipio_id: municipio_idTemp
            })
        }
    }, [fetchMapaColegiosData])

    useEffect(() => {

        const codigo_postalTemp = formValuesDarDeAlta.codigo_postal

        fetchMapaColegios({
            params: {
                colegio_id: formValuesDarDeAlta.colegio_id,
                codigo_postal: codigo_postalTemp
            }
        })

    }, [formValuesDarDeAlta.codigo_postal])

    // Modal de success

    const [showSuccess, setShowSuccess] = useState(false);

    const handleCloseSuccess = () => {
        setnumColegiado('')
        setShowSuccess(false)
    };
    const handleShowSuccess = () => setShowSuccess(true);

    // useEffect(() => {
    //     console.log(formValuesDarDeAlta)
    // }, [formValuesDarDeAlta])

    useEffect(() => {

        // handleShowSuccess()
    }, [])
    return (
        <>

            <Modal show={showModalDarDeAlta} onHide={handleCloseModalDarDeAlta2} size="lg" id="ModalDarDeAlta">
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Fitxa d'alta</Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-4 py-4'>
                    {
                        existeNIF === 'init' && <>
                            <div className="row justify-content-center">
                                <Form.Group className="col-12 col-sm-6 col-lg-5 col-xl-4 mb-4" controlId="exampleForm.ControlInput7">
                                    <Form.Label>NIF / NIE</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleInputChangeDarDeAlta}
                                        value={formValuesDarDeAlta.nif}
                                        name='nif'
                                        autoComplete="one-time-code"

                                        className={`text-center ${getMsgInputServerColor(fetchDarDeAltaExisteNIFData, 'nif') || getMsgInputColor(valuesValidNIF, 'nif') ? 'is-invalid' : ''}`}
                                    />
                                    <MsgInput obj={valuesValidNIF} name={'nif'} />
                                    <MsgInputServer obj={fetchDarDeAltaExisteNIFData?.data} name={'nif'} />
                                </Form.Group>

                                <div className="col-12 text-center">
                                    <Button
                                        variant="primary"
                                        type='submit'
                                        className='px-4'
                                        disabled={fetchDarDeAltaExisteNIFData.loading}
                                        // disabled={fetchDarDeAltaExisteNIFData.loading || formValuesDarDeAlta.nif.length !== 9}
                                        onClick={handleClickDarDeAltaExisteNIF}
                                    >
                                        {
                                            fetchDarDeAltaExisteNIFData.loading ?
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className='me-2'
                                                />
                                                : ''
                                        }
                                        Següent <FontAwesomeIcon icon={faArrowRight} className='ms-1' />
                                    </Button>
                                </div>

                            </div>
                        </>
                    }

                    {
                        existeNIF === 'noExiste' && <div className='row'>

                            <div className="col-12 mt-1 mb-4">
                                <h5 className='h5 my-0 text-center'>Crear el nou enginyer</h5>
                            </div>

                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput7">
                                <Form.Label>NIF / NIE</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleInputChangeDarDeAlta}
                                    value={formValuesDarDeAlta.nif}
                                    name='nif'
                                    autoComplete="one-time-code"
                                    disabled
                                    className={`${getMsgInputServerColor(fetchDarDeAltaCrearIngeniero, 'nif') || getMsgInputColor(valuesValidNoExisteNIF, 'nif') ? 'is-invalid' : ''}`}
                                />
                                <MsgInput obj={valuesValidNoExisteNIF} name={'nif'} />
                                <MsgInputServer obj={fetchDarDeAltaCrearIngeniero?.data} name={'nif'} />
                            </Form.Group>

                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-4 mb-4 " controlId="exampleForm.ControlInput7">
                                <Form.Label>Nom</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleInputChangeDarDeAlta}
                                    value={formValuesDarDeAlta.nombre}
                                    name='nombre'
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchDarDeAltaCrearIngeniero, 'nombre') || getMsgInputColor(valuesValidNoExisteNIF, 'nombre') ? 'is-invalid' : ''}`}
                                />
                                <MsgInput obj={valuesValidNoExisteNIF} name={'nombre'} />
                                <MsgInputServer obj={fetchDarDeAltaCrearIngeniero?.data} name={'nombre'} />
                            </Form.Group>

                            <Form.Group className="col-12 col-sm-6 col-lg-5 col-xl-5 mb-4" controlId="exampleForm.ControlInput7">
                                <Form.Label>Cognoms</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleInputChangeDarDeAlta}
                                    value={formValuesDarDeAlta.apellidos}
                                    name='apellidos'
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchDarDeAltaCrearIngeniero, 'apellidos') || getMsgInputColor(valuesValidNoExisteNIF, 'apellidos') ? 'is-invalid' : ''}`}
                                />
                                <MsgInput obj={valuesValidNoExisteNIF} name={'apellidos'} />
                                <MsgInputServer obj={fetchDarDeAltaCrearIngeniero?.data} name={'apellidos'} />
                            </Form.Group>

                            <div className="col-12 text-center mt-1 mb-3">
                                <Button
                                    variant="primary"
                                    type='submit'
                                    className='px-4'
                                    disabled={fetchDarDeAltaCrearIngenieroData.loading}
                                    onClick={handleClickDarDeAltaCrearIngeniero}
                                >
                                    {
                                        fetchDarDeAltaCrearIngenieroData.loading ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className='me-2'
                                            />
                                            : ''
                                    }
                                    Crear Enginyer <FontAwesomeIcon icon={faArrowRight} className='ms-1' />
                                </Button>
                            </div>
                        </div>
                    }

                    {
                        existeNIF === 'existe' && fetchDarDeAltaExisteNIFColegioData.loading && <div className='d-flex justify-content-center py-5'>
                            <Spinner
                                as="span"
                                animation="border"
                                size="xl"
                                role="status"
                                aria-hidden="true"
                                className='me-2'
                            />
                        </div>
                    }

                    {
                        existeNIF === 'existe' && existeNIFColegio === 'existe' && existeNIFColegioBaja === 'existe' && <div className='row'>

                            <div className="col-12">
                                <h5 className='mt-1 mb-3 text-center'>
                                    {formValuesDarDeAlta.nif} - {formValuesDarDeAlta.nombre + ' ' + formValuesDarDeAlta.apellidos} - ( {formValuesDarDeAlta.num_colegiado} )
                                </h5>
                            </div>

                            <Form.Group className="col-12 mb-4" controlId="exampleForm.ControlInput4">
                                <Form.Label className='mb-3 col-12 d-block'>
                                    Tipus d'alta
                                </Form.Label>
                                <div className="d-flex flex-wrap mb-2">
                                    {fetchParametersData?.data?.data?.estados_colegiacion?.map((item) => {
                                        if (item.tipo === 'baja' || item.id === 'alta' || item.id === 'alta_por_traslado') {
                                            return null
                                        }
                                        return (
                                            <div className='flex-grow-1 my-2 my-md-0' key={item.id}>
                                                <Form.Check
                                                    inline
                                                    label={`${item.nombre}`}
                                                    type='radio'
                                                    id={`inline-radio-${item.id}`}
                                                    defaultChecked={item.id === 'reingres_mateix'}
                                                    onChange={handleInputChangeDarDeAlta}
                                                    name='estado_id'
                                                    value={`${item.id}`}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                                <MsgInputServer obj={fetchDarDeAltaCrearColegiadoData?.data} name={'num_colegiado'} />
                            </Form.Group>

                            <Form.Group className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-4" controlId="exampleForm.ControlInput4">
                                <Form.Label>
                                    Data d'alta
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    onChange={handleInputChangeDarDeAlta}
                                    value={formValuesDarDeAlta.fecha_estado}
                                    name='fecha_estado'
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchDarDeAltaCrearColegiadoData, 'fecha_estado') || getMsgInputColor(valuesValidActivar, 'fecha_estado') ? 'is-invalid' : ''}`}
                                />
                                <MsgInput obj={valuesValidActivar} name={'fecha_estado'} />
                                <MsgInputServer obj={fetchDarDeAltaCrearColegiadoData?.data} name={'fecha_estado'} />
                            </Form.Group>

                            <Form.Group className="col-12 mb-4" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Observacions (opcional)</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    onChange={handleInputChangeDarDeAlta}
                                    value={formValuesDarDeAlta.observaciones}
                                    name='observaciones'
                                    autoComplete="one-time-code"
                                />
                            </Form.Group>

                            <h5 className='h6 mt-3 mb-3'>Domicili professional</h5>

                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>Codi postal</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleInputChangeDarDeAlta}
                                    value={formValuesDarDeAlta.codigo_postal}
                                    name='codigo_postal'
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchDarDeAltaCrearColegiadoData, 'codigo_postal') || getMsgInputColor(valuesValidActivar, 'codigo_postal') ? 'is-invalid' : ''}`}
                                />
                                <MsgInput obj={valuesValidActivar} name={'codigo_postal'} />
                                <MsgInputServer obj={fetchDarDeAltaCrearColegiadoData?.data} name={'codigo_postal'} />
                            </Form.Group>


                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput2">
                                <Form.Label>Municipi</Form.Label>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleInputChangeDarDeAlta}
                                    value={formValuesDarDeAlta.municipio_id}
                                    name='municipio_id'
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchDarDeAltaCrearColegiadoData, 'municipio_id') || getMsgInputColor(valuesValidActivar, 'municipio_id') ? 'is-invalid' : ''}`}

                                >
                                    {
                                        fetchMapaColegiosData?.data?.data?.map(item => {
                                            return <option key={item.municipio.id} value={item.municipio.id}>{item.municipio.nombre}</option>
                                        })
                                    }
                                </Form.Select>
                                <MsgInput obj={valuesValidActivar} name={'municipio_id'} />
                                <MsgInputServer obj={fetchDarDeAltaCrearColegiadoData?.data} name={'municipio_id'} />

                            </Form.Group>

                            <Form.Group className="col-12 col-lg-6 mb-4" controlId="exampleForm.ControlInput3">
                                <Form.Label>Adreça</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleInputChangeDarDeAlta}
                                    value={formValuesDarDeAlta.calle}
                                    name='calle'
                                    className={`${getMsgInputServerColor(fetchDarDeAltaCrearColegiadoData, 'calle') || getMsgInputColor(valuesValidActivar, 'calle') ? 'is-invalid' : ''}`}
                                    autoComplete="one-time-code"
                                />
                                <MsgInput obj={valuesValidActivar} name={'calle'} />
                                <MsgInputServer obj={fetchDarDeAltaCrearColegiadoData?.data} name={'calle'} />
                            </Form.Group>

                            <div className="col-12 text-center mt-1 mb-3">
                                <Button
                                    variant="primary"
                                    type='submit'
                                    className='px-4'
                                    disabled={fetchDarDeAltaCrearColegiadoData.loading}
                                    onClick={handleClickDarDeAltaActivarColegiado}
                                >
                                    {
                                        fetchDarDeAltaCrearColegiadoData.loading ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className='me-2'
                                            />
                                            : ''
                                    }
                                    <FontAwesomeIcon icon={faSave} className='me-1' /> Donar d'alta el col·legiat
                                </Button>
                            </div>

                        </div>
                    }

                    {
                        existeNIF === 'existe' && existeNIFColegio === 'existe' && existeNIFColegioBaja === 'noExiste' && <div className='row justify-content-center'>

                            <div className="col-12">
                                <Alert variant='warning' className='mb-5 mt-4 text-center'>
                                    Aquest Enginyer ja està registrat al Col·legi
                                </Alert>
                            </div>

                            <div className="col-12 text-center mt-1 mb-3">
                                <Button
                                    variant="secondary"
                                    type='submit'
                                    className='px-4'
                                    disabled={fetchDarDeAltaExisteNIFData.loading}
                                    onClick={handleCloseModalDarDeAlta2}
                                >
                                    {
                                        fetchDarDeAltaExisteNIFData.loading ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className='me-2'
                                            />
                                            : ''
                                    }
                                    Sortir
                                </Button>
                            </div>
                        </div>
                    }

                    {
                        existeNIF === 'existe' && existeNIFColegio === 'noExiste' && <div className='row'>

                            <div className="col-12">
                                <h5 className='mt-1 mb-4 text-center'>
                                    {formValuesDarDeAlta.nif} - {formValuesDarDeAlta.nombre + ' ' + formValuesDarDeAlta.apellidos}
                                </h5>
                            </div>

                            {
                                showColegiacionesActuales && <>
                                    <Form.Group className="col-12 mb-4" controlId="exampleForm.ControlInput4">

                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-8">
                                                <Form.Label className='mb-3 col-12 d-block'>
                                                    Tipus d'alta
                                                </Form.Label>
                                                <div className="row">
                                                    <div className='col-md-6 my-2 my-md-0'>
                                                        <Form.Check
                                                            inline
                                                            label='Alta'
                                                            type='radio'
                                                            id='alta'
                                                            defaultChecked={true}
                                                            onChange={handleInputChangeDarDeAlta}
                                                            name='estado_id'
                                                            value='alta'
                                                            onClick={() => setChangeAlta(false)}
                                                        />
                                                    </div>
                                                    <div className='col-md-6 my-2 my-md-0'>
                                                        <Form.Check
                                                            inline
                                                            label='Alta per Trasllat'
                                                            type='radio'
                                                            id='alta_por_traslado'
                                                            onChange={handleInputChangeDarDeAlta}
                                                            name='estado_id'
                                                            value='alta_por_traslado'
                                                            onClick={() => setChangeAlta(true)}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12 col-md-4">
                                                {
                                                    changeAlta && <>
                                                        <Form.Label>Núm. Col·legiat</Form.Label>
                                                        <Form.Select aria-label="Default select example"
                                                            onChange={handleInputChangeDarDeAlta}
                                                            value={formValuesDarDeAlta.num_colegiado}
                                                            name='num_colegiado'
                                                            autoComplete="one-time-code"
                                                        // className={`${getMsgInputServerColor(fetchDarDeAltaCrearColegiadoData, 'num_colegiado') ? 'is-invalid' : ''}`}
                                                        >
                                                            {
                                                                formValuesDarDeAlta.colegiaciones_actuales.map(item => {
                                                                    if (item.estado_id !== 'baja') {
                                                                        return null
                                                                    }
                                                                    return <option key={item.num_colegiado} value={item.num_colegiado}>{fetchParametersData?.data?.data?.colegios.filter((elem) => elem.id === item.colegio_id)[0].nombre} - {item.num_colegiado}</option>
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </>
                                                }
                                            </div>
                                            <MsgInputServer obj={fetchDarDeAltaCrearColegiadoData?.data} name={'num_colegiado'} />
                                        </div>
                                    </Form.Group>

                                </>
                            }
                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-4 mb-4" controlId="exampleForm.ControlInput4">
                                <Form.Label>Data d'alta</Form.Label>
                                <Form.Control
                                    type="date"
                                    onChange={handleInputChangeDarDeAlta}
                                    value={formValuesDarDeAlta.fecha_estado}
                                    name='fecha_estado'
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchDarDeAltaCrearColegiadoData, 'fecha_estado') || getMsgInputColor(valuesValidCrearColegiado, 'fecha_estado') ? 'is-invalid' : ''}`}
                                />
                                <MsgInput obj={valuesValidCrearColegiado} name={'fecha_estado'} />
                                <MsgInputServer obj={fetchDarDeAltaCrearColegiadoData?.data} name={'fecha_estado'} />
                            </Form.Group>

                            <div className="col-s12"></div>

                            <Form.Group className="col-12 mb-4" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Observacions (opcional)</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    onChange={handleInputChangeDarDeAlta}
                                    value={formValuesDarDeAlta.observaciones}
                                    name='observaciones'
                                    autoComplete="one-time-code"
                                />
                            </Form.Group>

                            <h5 className='h6 mt-3 mb-3'>Domicili professional</h5>

                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>Codi postal</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleInputChangeDarDeAlta}
                                    value={formValuesDarDeAlta.codigo_postal}
                                    name='codigo_postal'
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchDarDeAltaCrearColegiadoData, 'codigo_postal') || getMsgInputColor(valuesValidCrearColegiado, 'codigo_postal') ? 'is-invalid' : ''}`}
                                />
                                <MsgInput obj={valuesValidCrearColegiado} name={'codigo_postal'} />
                                <MsgInputServer obj={fetchDarDeAltaCrearColegiadoData?.data} name={'codigo_postal'} />
                            </Form.Group>


                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput2">
                                <Form.Label>Municipi</Form.Label>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleInputChangeDarDeAlta}
                                    value={formValuesDarDeAlta.municipio_id}
                                    name='municipio_id'
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchDarDeAltaCrearColegiadoData, 'municipio_id') || getMsgInputColor(valuesValidCrearColegiado, 'municipio_id') ? 'is-invalid' : ''}`}

                                >
                                    {
                                        fetchMapaColegiosData?.data?.data?.map(item => {
                                            return <option key={item.municipio.id} value={item.municipio.id}>{item.municipio.nombre}</option>
                                        })
                                    }
                                </Form.Select>
                                <MsgInput obj={valuesValidCrearColegiado} name={'municipio_id'} />
                                <MsgInputServer obj={fetchDarDeAltaCrearColegiadoData?.data} name={'municipio_id'} />

                            </Form.Group>

                            <Form.Group className="col-12 col-lg-6 mb-4" controlId="exampleForm.ControlInput3">
                                <Form.Label>Adreça</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleInputChangeDarDeAlta}
                                    value={formValuesDarDeAlta.calle}
                                    name='calle'
                                    className={`${getMsgInputServerColor(fetchDarDeAltaCrearColegiadoData, 'calle') || getMsgInputColor(valuesValidCrearColegiado, 'calle') ? 'is-invalid' : ''}`}
                                    autoComplete="one-time-code"
                                />
                                <MsgInput obj={valuesValidCrearColegiado} name={'calle'} />
                                <MsgInputServer obj={fetchDarDeAltaCrearColegiadoData?.data} name={'calle'} />
                            </Form.Group>

                            <div className="col-12 text-center mt-1 mb-3">
                                <Button
                                    variant="primary"
                                    type='submit'
                                    className='px-4'
                                    disabled={fetchDarDeAltaCrearColegiadoData.loading}
                                    onClick={handleClickDarDeAltaCrearColegiado}
                                >
                                    {
                                        fetchDarDeAltaCrearColegiadoData.loading ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className='me-2'
                                            />
                                            : ''
                                    }
                                    <FontAwesomeIcon icon={faSave} className='me-1' /> Registrar col·legiat
                                </Button>
                            </div>
                        </div>
                    }

                </Modal.Body>
            </Modal >
            <Modal show={showSuccess} onHide={handleCloseSuccess}>
                <Modal.Header closeButton>
                    <Modal.Title>Alta reeixida</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="py-4 text-center">
                        <p>L'enginyer ha estat registrat amb el número de col·legiat: </p>
                        <h4 className='mb-0'>{numColegiado}</h4>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSuccess}>
                        Sortir
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
