import { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router';
import { AuthContext } from '../auth/AuthContext';
import { types } from '../types/types';

export const useFetch = (method, url, isDownload, type = 'json') => {

    const { user: { token }, dispatch } = useContext(AuthContext);
    const history = useHistory();
    const isMounted = useRef(true);
    const [state, setState] = useState({ data: null, loading: null, error: null });

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])

    const existenErroresAuth = (data) => {
        if (data && data.status === 'Error' &&
            (data.message === 'El token ha expirat' ||
                data.message === `No s'ha trobat el token` ||
                data.message === `La sessió no existeix` ||
                data.message === `El token és invàlid`)) {
            
            if (window.location.pathname.indexOf('/login') === -1) {
                history.replace('/login');
                dispatch({
                    type: types.logout
                });
            }
            return true
        }
        return false
    }

    const handleFetch = (values) => {

        setState({ data: null, loading: true, error: null });

        const requestOptions = {
            method,
            headers: {
                'Content-Type': 'application/json'
            }
        }

        if (token) {
            requestOptions.headers.Authorization = 'Bearer ' + token
        }

        if (method === 'POST' || method === 'PUT' || method === 'PATCH') {

            requestOptions.body = JSON.stringify({ ...values?.body })
        }

        if (values?.id) {
            url = url + '/' + values.id
        }

        if (values?.params) {
            let params = values.params
            let query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
            url = url + '?' + query
        }

        if (type === 'json') {

            if (isDownload === 'download') {
                fetch(url, requestOptions)
                    .then(resp => resp.blob())
                    .then(data => {
                        // comprobarErrores(data)
                        if (!existenErroresAuth(data) && isMounted.current) {
                            setState({
                                loading: false,
                                error: null,
                                data,
                                setState
                            });
                        }
                    })
                    .catch(() => {
                        setState({
                            data: null,
                            loading: false,
                            error: 'No se pudo cargar la info'
                        })
                    })
            } else {
                fetch(url, requestOptions)
                    .then(resp => resp.json())
                    .then(data => {
                        // comprobarErrores(data)
                        if (!existenErroresAuth(data) && isMounted.current) {
                            setState({
                                loading: false,
                                error: null,
                                data
                            });
                        }
                    })
                    .catch(() => {
                        setState({
                            data: null,
                            loading: false,
                            error: 'No se pudo cargar la info'
                        })
                    })
            }
        }

        if (type === 'text') {
            fetch(url, requestOptions)
                .then(resp => resp.text())
                .then(data => {
                    // comprobarErrores(data)
                    if (!existenErroresAuth(data) && isMounted.current) {
                        setState({
                            loading: false,
                            error: null,
                            data: data
                        });
                    }
                })
                .catch(() => {
                    setState({
                        data: null,
                        loading: false,
                        error: 'No se pudo cargar la info'
                    })
                })
        }

    }

    return [handleFetch, state];
}