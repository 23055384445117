import queryString from 'query-string';

export function getQueryStringSearchCollegiats(objParams) {
    return `?${queryString.stringify({
        colegio_id: objParams.colegio_id ? objParams.colegio_id : undefined,
        page: objParams.page !== '' ? objParams.page : undefined
      })}`
}

export function getMsgInputServerColor(objParams, name){
  return objParams?.data?.status === 'Error' && objParams?.data?.data[name]
}

export function getMsgInputColor(objParams, name){
  return objParams[name]?.required && !objParams[name]?.required?.isOk
}