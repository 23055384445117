import React, { useEffect } from 'react'
import { useLocation } from 'react-router';

import { Breadcrumb} from 'react-bootstrap'

import { useForm } from '../../hooks/useForm';
import { useFetch } from '../../hooks/useFetch';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import queryString from 'query-string';

import { CollegiatsFilter } from '../../components/CollegiatsFilter/CollegiatsFilter';
import { CollegiatsMain } from '../../components/CollegiatsMain/CollegiatsMain';
import './Collegiats.css'

export const Collegiats = ({ namePage }) => {

    const location = useLocation()

    // Start Fetch - Obtener lista de colegios

    const [fetchParameters, fetchParametersData] = useFetch('GET', `${process.env.REACT_APP_API_URL}/parametros`)

    useEffect(() => {

        fetchParameters({
            params: {
                'colegios': 1,
                'perfiles_usuario': 1,
                'estados_colegiacion' : 1,
            }
        });

    }, [])

    // End Fetch - Obtener lista de colegios

    const [fetchCollegiats, fetchCollegiatsData] = useFetch('GET', `${process.env.REACT_APP_API_URL}/colegiaciones`)

    const {
        estado_id: estado_idParam = '',
        nif: nifParam = '',
        num_colegiado: num_colegiadoParam = '',
        search_text: search_textParam = '',
        colegio_id: colegio_idParam = '',
        page: pageParam = 1,
        orderby: orderbyParam = ''
    } = queryString.parse(location.search);

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        nif: nifParam,
        num_colegiado: num_colegiadoParam,
        search_text: search_textParam,
        colegio_id: colegio_idParam,
        page: pageParam,
        orderby: orderbyParam,
        estado_id: estado_idParam
    });

    const handleSearch = (e) => {

        if (e) {
            e.preventDefault();
        }

        let urlParams = {}

        if (formValuesSearch.nif) {
            urlParams.nif = formValuesSearch.nif
        }

        if (formValuesSearch.num_colegiado) {
            urlParams.num_colegiado = formValuesSearch.num_colegiado
        }

        if (formValuesSearch.search_text) {
            urlParams.search_text = formValuesSearch.search_text
        }

        if (formValuesSearch.colegio_id) {
            urlParams.colegio_id = formValuesSearch.colegio_id
        }
        if (formValuesSearch.page) {
            urlParams.page = formValuesSearch.page
        }
        if (formValuesSearch.orderby) {
            urlParams.orderby = formValuesSearch.orderby
        }
        if (formValuesSearch.estado_id) {
            urlParams.estado_id = formValuesSearch.estado_id
        }

        const query = Object.keys(urlParams).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(urlParams[k])).join('&');

        // history.push(`?${query}`);
        window.history.replaceState(null, null, `?${query}`);

        if (formValuesSearch.page !== 1) {

            handleFormChange({
                ...formValuesSearch,
                page: 1
            });

        } else {

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchCollegiats({
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }
    }

    useEffect(() => {

        let formValuesSearchTemp = { ...formValuesSearch }

        Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

        const query = Object.keys(formValuesSearchTemp).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(formValuesSearchTemp[k])).join('&');

        window.history.replaceState(null, null, `?${query}`);

        fetchCollegiats({
            params: {
                ...formValuesSearchTemp
            }
        })

    }, [formValuesSearch.page, formValuesSearch.orderby])

    return (
        <div className="container-inner py-3 py-md-4 row justify-content-between">

            <Breadcrumb className="Breadcrumb-inner mt-lg-3">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {namePage}
                </Breadcrumb.Item>
            </Breadcrumb>

            <CollegiatsFilter
                handleSearch={handleSearch}
                handleInputChangeSearch={handleInputChangeSearch}
                formValuesSearch={formValuesSearch}
                fetchParametersData={fetchParametersData}
            />

            <CollegiatsMain
                fetchCollegiatsData={fetchCollegiatsData}
                formValuesSearch={formValuesSearch}
                namePage={namePage}
                handleFormChange={handleFormChange}
                fetchParametersData={fetchParametersData}
                fetchCollegiats={fetchCollegiats}
            />

        </div>
    )
}
