import { faBars, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Dropdown, Form, Modal, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import { getMsgInputColor, getMsgInputServerColor } from '../../helpers/helpers';
import { useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';
import { useValidator } from '../../hooks/useValidator';
import { types } from '../../types/types';
import { MsgInput } from '../MsgInput/MsgInput';
import { MsgInputServer } from '../MsgInput/MsgInputServer';
import './NavtopInner.css'

export const NavtopInner = ({ setShow, show }) => {

    const { user: { nombre, apellidos }, dispatch } = useContext(AuthContext);
    const history = useHistory();

    const handleLogout = () => {

        history.replace('/login');

        dispatch({
            type: types.logout
        });
    }

    
    const [showModalChangePassword, setShowModalChangePassword] = useState(false);

    const handleClose = () => setShowModalChangePassword(false);
    const handleShow = () => setShowModalChangePassword(true);

    useEffect(() => {
        if (!showModalChangePassword) {

            resetFormValuesPassword()

            resetValuesValidPassword()
        }
    }, [showModalChangePassword])

    const [fetchPassword, fetchPasswordData] = useFetch('POST', `${process.env.REACT_APP_API_URL}/usuarios/cambiar_password`)

    const [formValuesPassword, handleInputChangePassword, handleFormChangePassword, resetFormValuesPassword] = useForm({
        old_password: '',
        new_password: '',
        new_password_confirmation: ''
    })
    
    const [valuesValidPassword, handleVerifiedValuesPassword, handleAreAllValuesCorrectPassword, resetValuesValidPassword] = useValidator({
        old_password: {
            required: {
                isOk: true,
                msgError: [`El camp old password és obligatori.`]
            }
        },
        new_password: {
            required: {
                isOk: true,
                msgError: [`El camp new password és obligatori.`]
            }
        },
        new_password_confirmation: {
            required: {
                isOk: true,
                msgError: [`El camp new password és obligatori.`]
            }
        }
    })

    const handleSubmitCreateUser = (e) => {

        handleVerifiedValuesPassword(formValuesPassword)

        if (valuesValidPassword && handleAreAllValuesCorrectPassword()) {

            fetchPassword({
                body: {
                    ...formValuesPassword
                }
            })
        }
    }

    useEffect(() => {

        if (fetchPasswordData.data?.status === 'Success') {

            handleClose()
        }

    }, [fetchPasswordData])

    return (
        <section className="NavtopInner d-flex justify-content-between align-items-center shadow-sm">
            <div className="fw-bold">
                <h4 className="my-0">
                    <FontAwesomeIcon icon={faBars} className='me-3 d-md-none' id='icon-bars' onClick={() => setShow(!show)} />
                    <span className='fw-bolder'>CCETIC</span>
                </h4>
            </div>
            <div className="d-flex align-items-center">
                <Dropdown align="end">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <span className="me-2">{nombre} {apellidos}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='text-end'>
                        <Dropdown.Item onClick={handleShow}>Canviar contrasenya</Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout}>Sortir</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <Modal show={showModalChangePassword} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Canviar contrasenya</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-4'>
                    <Form className='row'>
                        <Form.Group className="col-12 col-lg-6 mb-3" controlId="exampleForm.ControlInput7">
                            <Form.Label>Contrasenya antiga</Form.Label>
                            <Form.Control
                                type="password"
                                name='old_password'
                                value={formValuesPassword.old_password}
                                onChange={handleInputChangePassword}
                                autoComplete="one-time-code"
                                className={`${getMsgInputServerColor(fetchPasswordData, 'old_password') || getMsgInputColor(valuesValidPassword, 'old_password') ? 'is-invalid' : ''}`}
                            />

                            <MsgInputServer obj={fetchPasswordData?.data} name={'old_password'} />
                            <MsgInput obj={valuesValidPassword} name={'old_password'} />
                        </Form.Group>

                        <div className="col-12"></div>

                        <Form.Group className="col-12 col-lg-6 mb-3" controlId="exampleForm.ControlInput7">
                            <Form.Label>Contrasenya nova</Form.Label>
                            <Form.Control
                                type="password"
                                name='new_password'
                                value={formValuesPassword.new_password}
                                onChange={handleInputChangePassword}
                                autoComplete="one-time-code"
                                className={`${getMsgInputServerColor(fetchPasswordData, 'new_password') || getMsgInputColor(valuesValidPassword, 'new_password') ? 'is-invalid' : ''}`}
                            />
                            <MsgInputServer obj={fetchPasswordData?.data} name={'new_password'} />
                            <MsgInput obj={valuesValidPassword} name={'new_password'} />
                        </Form.Group>

                        <Form.Group className="col-12 col-lg-6 mb-3" controlId="exampleForm.ControlInput7">
                            <Form.Label>Confirmació de contrasenya nova</Form.Label>
                            <Form.Control
                                type="password"
                                name='new_password_confirmation'
                                value={formValuesPassword.new_password_confirmation}
                                onChange={handleInputChangePassword}
                                autoComplete="one-time-code"
                                className={`${getMsgInputServerColor(fetchPasswordData, 'new_password_confirmation') || getMsgInputColor(valuesValidPassword, 'new_password_confirmation') ? 'is-invalid' : ''}`}
                            />
                            <MsgInputServer obj={fetchPasswordData?.data} name={'new_password_confirmation'} />
                            <MsgInput obj={valuesValidPassword} name={'new_password_confirmation'} />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel·lar
                    </Button>
                    <Button variant="primary" onClick={handleSubmitCreateUser} className='ms-2' disabled={fetchPasswordData.loading}>
                        {
                            fetchPasswordData.loading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className='me-2'
                                />
                                : ''
                        }
                        <FontAwesomeIcon icon={faSave} className='me-1' /> Canviar
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    )
}
