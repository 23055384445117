import React, { useState } from 'react'
import { NavStartInner } from '../NavStartInner/NavStartInner'
import { NavtopInner } from '../NavtopInner/NavtopInner'

export const LayoutNavs = () => {
    
    const [show, setShow] = useState(false)

    return (
        <>
            <NavtopInner setShow={setShow} show={show}/>
            <NavStartInner setShow={setShow} show={show}/>
            <div className={show ? 'toggle-fade-menu' : ''} onClick={() => setShow(false)} id='fade-menu'></div>
        </>
    )
}
