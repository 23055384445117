import { useState } from 'react';

export const useValidator = (initialState) => {

    const [valuesValid, setvaluesValid] = useState(initialState)

    const handleVerifiedValues = (objValues, exceptKeys = []) => {

        const valuesValidTemp = { ...valuesValid }

        for (const key in valuesValidTemp) {

            if (valuesValidTemp[key].required) {

                if (objValues[key] !== '' || exceptKeys.indexOf(key) !== -1) {

                    valuesValidTemp[key]['required']['isOk'] = true

                } else {

                    valuesValidTemp[key]['required']['isOk'] = false
                }
            }
        }

        setvaluesValid({
            ...valuesValidTemp
        })
    }

    const handleAreAllValuesCorrect = () => {

        const objParse = JSON.stringify(valuesValid)

        // console.log(objParse)

        if (objParse.indexOf('"isOk":false') === -1) {
            return true
        }

        return false
    }

    const resetValuesValidColegiacion = () => {

        const valuesValidTemp = { ...valuesValid }

        for (const key in valuesValidTemp) {

            if (valuesValidTemp[key].required) {

                valuesValidTemp[key]['required']['isOk'] = true
            }
        }

        setvaluesValid({
            ...valuesValidTemp
        })
    }

    return [valuesValid, handleVerifiedValues, handleAreAllValuesCorrect, resetValuesValidColegiacion]

}