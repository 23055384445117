import React, { useState } from 'react'
import { Card, Button, Form, Modal, Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { images } from '../../helpers/images';

import { useForm } from '../../hooks/useForm';
import { useValidator } from '../../hooks/useValidator';

import { useFetch } from '../../hooks/useFetch';
import { MsgInputServer } from '../MsgInput/MsgInputServer';
import { MsgInput } from '../MsgInput/MsgInput';

import 'animate.css'
import './CardLogin.css'

export const CardLogin = ({ handleLogin, datosIncorrectos, setDatosIncorrectos, fetchUserData }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formValues, handleInputChange] = useForm({
        email: '',
        password: ''
    })

    const {
        email,
        password
    } = formValues

    const [valuesValidLogin, handleVerifiedValuesLogin, handleAreValuesLogin] = useValidator({
        email: true,
        password: true
    })

    const handleSubmitForm = (e) => {

        e.preventDefault()

        setDatosIncorrectos(false)

        handleVerifiedValuesLogin(formValues)

        if (handleAreValuesLogin(formValues)) {
            handleLogin(formValues)
        }
    }


    // Recuperar Contraseña

    const [fetchRecuperarPassword, fetchRecuperarPasswordData] = useFetch('POST', `${process.env.REACT_APP_API_URL}/auth/reiniciar_password`)

    const [formValuesRecuperarPassword, handleInputChangeRecuperarPassword] = useForm({
        email: ''
    })

    const [valuesValidRecuperarPassword, handleVerifiedValuesRecuperarPassword, handleAreAllValuesCorrectRecuperarPassword] = useValidator({
        email: {
            required: {
                isOk: true,
                msgError: [`L'email és necessari.`]
            }
        }
    })

    const handleSubmitRecuperarPassword = (e) => {

        e.preventDefault()

        handleVerifiedValuesRecuperarPassword(formValuesRecuperarPassword)

        if (valuesValidRecuperarPassword && handleAreAllValuesCorrectRecuperarPassword()) {

            fetchRecuperarPassword({
                body: {
                    ...formValuesRecuperarPassword
                }
            })
        }
    }

    return (
        <>
            <div className="col-10 col-sm-7 col-lg-10 col-xl-8 col-xxl-6 py-md-3 text-start">
                <img src={images('./logo.png').default ? images('./logo.png').default : images('./logo.png')} className="card-img" alt="logo" id="logo" />
            </div>

            <div className="w-100 d-flex justify-content-center align-items-center flex-column flex-grow-1 mb-lg-5 animate__animated animate__fadeInUp">
                <h3 className="title-ccetic mt-5 mt-lg-0">CCETIC</h3>
                <Card className="col-10 col-sm-7 col-lg-10 col-xl-8 col-xxl-6 shadow-lg py-md-3 mb-5">
                    {/* <Card.Img variant="top" src="images/logo.png" id="logo" /> */}
                    <Card.Body className="px-4">
                        <Card.Title className="text-center mb-3">Inicia sessió</Card.Title>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>E-mail:</Form.Label>
                                <Form.Control
                                    type="email"
                                    className={`${!valuesValidLogin.email ? 'is-invalid' : ''}`}
                                    name='email'
                                    value={email}
                                    onChange={handleInputChange} />
                                {
                                    !valuesValidLogin.email ?
                                        <Form.Text className='text-danger'>L'email és necessari</Form.Text> : null
                                }

                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Contrasenya:</Form.Label>
                                <Form.Control
                                    type="password"
                                    className={`${!valuesValidLogin.password ? 'is-invalid' : ''}`}
                                    name='password'
                                    value={password}
                                    onChange={handleInputChange} />
                                {
                                    !valuesValidLogin.password ?
                                        <Form.Text className='text-danger'>La contrasenya és necessària</Form.Text> : null
                                }
                            </Form.Group>
                            {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Check me out" />
                            </Form.Group> */}
                            {
                                datosIncorrectos ? <p className='text-danger text-center'>E-mail o password incorrectes</p> : null
                            }
                            <div className="text-end mt-4 d-flex flex-wrap justify-content-between align-items-center">
                                <Button type="button" id="passwordHelp" className="form-text my-0" onClick={handleShow}>
                                    Heu perdut la clau?
                                </Button>
                                <Button variant="primary" type="submit" className="px-4" onClick={handleSubmitForm} disabled={fetchUserData.loading}>
                                    {
                                        fetchUserData.loading ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className='me-1'
                                            />
                                            : ''
                                    } Entrada
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </div>

            <footer className="footer-login d-flex">
                <ul className="list-none m-0 p-0 d-flex flex-column flex-sm-row">
                    <li className="text-center mb-3 mb-sm-0">
                        <Link to="/avis-legal">Avís Legal</Link>
                    </li>
                    <li className="text-center mb-3 mb-sm-0">
                        <Link to="/politica-privacitat">Política de Privacitat</Link>
                    </li>
                    <li className="text-center mb-3 mb-sm-0">
                        <Link to="/politica-cookies">Política de Cookies</Link>
                    </li>
                </ul>
            </footer>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Recuperar password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {
                                    fetchRecuperarPasswordData?.data?.status === 'Success' ?

                                        <div className="py-3 text-center">
                                            {fetchRecuperarPasswordData.data.message}
                                        </div>

                                        :
                                        <Form>
                                            <Form.Group className="mb-3" controlId="formBasicEmail2">
                                                <Form.Label>E-mail:</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    className={`${!valuesValidRecuperarPassword.email.required.isOk ? 'is-invalid' : ''}`}
                                                    name='email'
                                                    value={formValuesRecuperarPassword?.email}
                                                    onChange={handleInputChangeRecuperarPassword} />
                                                <MsgInputServer obj={fetchRecuperarPasswordData?.data} name={'email'} />
                                                <MsgInput obj={valuesValidRecuperarPassword} name={'email'} />

                                            </Form.Group>
                                        </Form>
                                }

                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Tancar
                    </Button>
                    <Button variant="primary" onClick={handleSubmitRecuperarPassword} className='ms-2' disabled={fetchRecuperarPasswordData.loading || fetchRecuperarPasswordData.data}>
                        Recuperar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}