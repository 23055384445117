import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';
import { CardLogin } from '../../components/CardLogin/CardLogin';
import { CarouselImages } from '../../components/CarouselImages/CarouselImages';
import { useFetch } from '../../hooks/useFetch';
import jwt from 'jwt-decode' // import dependency

import './Login.css'

export const Login = ({ history }) => {

    const { dispatch } = useContext(AuthContext);

    const [fetchUser, fetchUserData] = useFetch('POST', `${process.env.REACT_APP_API_URL}/auth/login`)

    const [datosIncorrectos, setDatosIncorrectos] = useState(false)

    const handleLogin = (values) => {

        fetchUser({
            body: values
        })
    }

    useEffect(() => {

        if (fetchUserData.data) {

            if (fetchUserData?.data?.data?.token) {

                const lastPath = localStorage.getItem('lastPath') || '/';

                const user = jwt(fetchUserData?.data?.data?.token).user
                dispatch({
                    type: types.login,
                    payload: {
                        ...user,
                        token: fetchUserData?.data?.data?.token
                    }
                });

                history.replace(lastPath);

            } else {

                setDatosIncorrectos(true)
            }
        }

    }, [fetchUserData])

    return (
        <div className="App">
            <header className="App-header">
                <section id="login-start" className="float-start">
                    <CardLogin handleLogin={handleLogin} datosIncorrectos={datosIncorrectos} setDatosIncorrectos={setDatosIncorrectos} fetchUserData={fetchUserData}/>
                </section>

                <section id="login-end" className="shadow-lg position-relative float-end">
                    <CarouselImages />
                </section>

            </header>
        </div>
    )
}
