import React, { useEffect, useContext } from 'react'
import { useLocation } from 'react-router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

import { Breadcrumb } from 'react-bootstrap'
import { useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm'
import { UsuarisFilter } from '../../components/UsuarisFilter/UsuarisFilter'
import { UsuarisMain } from '../../components/UsuarisMain/UsuarisMain'
import queryString from 'query-string';

import { AuthContext } from '../../auth/AuthContext';

import './Usuaris.css'

export const Usuaris = ({ namePage }) => {

    const location = useLocation()

    const [fetchUsuarios, fetchUsuariosData] = useFetch('GET', `${process.env.REACT_APP_API_URL}/usuarios`)
    const [fetchParameters, fetchParametersData] = useFetch('GET', `${process.env.REACT_APP_API_URL}/parametros`)

    const { user: { colegio, perfil } } = useContext(AuthContext)

    const {
        search_text: search_textParam = '',
        colegio_id: colegio_idParam = '',
        page: pageParam = 1,
        orderby: orderbyParam = '',
        perfil_id: perfil_idParam = ''
    } = queryString.parse(location.search);

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        page: pageParam,
        perfil_id: perfil_idParam,
        orderby: orderbyParam,
        colegio_id: colegio_idParam,
        search_text: search_textParam
    });

    useEffect(() => {

        fetchParameters({
            params: {
                'colegios': 1,
                'perfiles_usuario': 1
            }
        });

    }, [])


    const handleSearch = (e) => {

        if (e) {
            e.preventDefault();
        }

        let urlParams = {}

        if (formValuesSearch.search_text) {
            urlParams.search_text = formValuesSearch.search_text
        }
        if (formValuesSearch.colegio_id) {
            urlParams.colegio_id = formValuesSearch.colegio_id
        }
        if (formValuesSearch.page) {
            urlParams.page = formValuesSearch.page
        }
        if (formValuesSearch.orderby) {
            urlParams.orderby = formValuesSearch.orderby
        }
        if (formValuesSearch.perfil_id) {
            urlParams.perfil_id = formValuesSearch.perfil_id
        }

        const query = Object.keys(urlParams).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(urlParams[k])).join('&');

        // history.push(`?${query}`);
        window.history.replaceState(null, null, `?${query}`);

        if (formValuesSearch.page !== 1) {

            handleFormChange({
                ...formValuesSearch,
                page: 1
            });

        } else {

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchUsuarios({
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }
    }

    useEffect(() => {

        let formValuesSearchTemp = { ...formValuesSearch }

        Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

        const query = Object.keys(formValuesSearchTemp).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(formValuesSearchTemp[k])).join('&');

        window.history.replaceState(null, null, `?${query}`);

        fetchUsuarios({
            params: {
                ...formValuesSearchTemp
            }
        })

    }, [formValuesSearch.page, formValuesSearch.orderby])

    return (
        <div className="container-inner py-3 py-md-4 row justify-content-between">
            <Breadcrumb className="Breadcrumb-inner mt-lg-3">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {namePage}
                </Breadcrumb.Item>
            </Breadcrumb>

            <UsuarisFilter
                handleSearch={handleSearch}
                handleInputChangeSearch={handleInputChangeSearch}
                formValuesSearch={formValuesSearch}
                fetchParametersData={fetchParametersData}
                colegio={colegio}
                perfil={perfil}
            />

            <UsuarisMain
                namePage={namePage}
                fetchUsuariosData={fetchUsuariosData}
                fetchUsuarios={fetchUsuarios}
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
                fetchParametersData={fetchParametersData}
                colegio={colegio}
                perfil={perfil}
            />

        </div>
    )
}
