import React from 'react'
import { Link } from 'react-router-dom';
import { images } from '../../helpers/images';
import { NavLinksInner } from '../NavLinksInner/NavLinksInner';

import './NavStartInner.css'

export const NavStartInner = ({setShow, show}) => {
    return (
        <section className={`NavStartInner position-fixed d-flex flex-column ${show ? 'toggle-menu' : ''}`} >
            <h4 className='d-md-none'>CCETIC</h4>
            <img src={images('./logo.png').default ? images('./logo.png').default : images('./logo.png')} className="mx-auto d-block" alt="logo" id="logo-inner" />
            <NavLinksInner />
            <footer className="px-3 mb-3 mt-auto me-4">

                <small>
                    <p className='mb-1'>2021 © Consell d'Enginyers Graduats i Enginyers Tècnics Industrials de
                        Catalunya</p>
                    <ul className="list-none m-0 p-0 d-flex flex-wrap">
                        <li className="text-center mb-0 me-2">
                            <Link to="/avis-legal">Avís Legal</Link>
                        </li>
                        <li className="text-center mb-0 me-2">
                            <Link to="/politica-privacitat">Política de Privacitat</Link>
                        </li>
                        <li className="text-center mb-0 me-2">
                            <Link to="/politica-cookies">Política de Cookies</Link>
                        </li>
                    </ul>
                </small>
            </footer>
        </section>
    )
}
