import React, { useEffect } from 'react'
import { Table, Breadcrumb } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome} from '@fortawesome/free-solid-svg-icons'

import './Collegis.css'
import { useFetch } from '../../hooks/useFetch';
import { Loader } from '../../components/Loader/Loader';

export const Collegis = ({ namePage }) => {

    const [fetchCollegis, fetchCollegisData] = useFetch('GET',`${process.env.REACT_APP_API_URL}/parametros?colegios=1`)

    useEffect(() => {
        fetchCollegis()
    }, [])

    return (
        <div className="container-inner p-3 p-md-4 row justify-content-between">
            <Breadcrumb className="Breadcrumb-inner mt-lg-3">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {namePage}
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className="MainInner shadow-sm animate__animated animate__fadeInUp col">
                <div className="d-flex justify-content-between mb-4">
                    <div>
                        <h5>Llista de {namePage}</h5>
                    </div>
                </div>
                {
                    fetchCollegisData?.loading ?
                        <Loader /> :
                        <Table hover bordered responsive className=''>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nom de el Col·legi</th>
                                </tr>
                            </thead>
                            <tbody>

                                {fetchCollegisData?.data?.data?.colegios?.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{item.id}</td>
                                            <td>{item.nombre}</td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </Table>
                }
            </div>
        </div>

    )
}
