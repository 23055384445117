import React, { useReducer, useEffect } from 'react'

import { AppRouter } from './routers/AppRouter'
import { AuthContext } from './auth/AuthContext'
import { authReducer } from './auth/authReducer'

import 'bootstrap/dist/css/bootstrap.min.css';
import './CCETICApp.css';

const init = () => {
    
    return JSON.parse(localStorage.getItem('user')) || { logged: false };
}

export const CCETICApp = () => {
    
    const [ user, dispatch ] = useReducer(authReducer, {}, init);

    useEffect(() => {

        localStorage.setItem( 'user', JSON.stringify(user) );

    }, [user])

    return (
        <AuthContext.Provider value={{ user, dispatch }}>
            <AppRouter />
        </AuthContext.Provider>
    )
}
