import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Form } from 'react-bootstrap'

export const UsuarisFilter = ({ fetchParametersData, formValuesSearch, handleInputChangeSearch, handleSearch, colegio, perfil }) => {

    return (
        <div className='col-xl-3'>
            <div className='Column-small shadow-sm sticky-top'>

                <h5>Filtre</h5>
                <Form className='row' onSubmit={handleSearch}>

                    <Form.Group className="col-12 col-sm-4 col-xl-12 mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email / Nom complet</Form.Label>
                        <Form.Control
                            type="text"
                            autoComplete="off"
                            value={formValuesSearch.search_text}
                            name='search_text'
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label>Perfil</Form.Label>
                        <Form.Select aria-label="Default select example"
                            value={formValuesSearch.perfil_id}
                            name='perfil_id'
                            onChange={handleInputChangeSearch}>
                            <option></option>
                            {fetchParametersData?.data?.data?.perfiles_usuario?.map((item) => {
                                if (perfil.id === 'administrador_colegio' && item.id === 'administrador') {
                                    return null
                                }
                                return (
                                    <option key={item.id} value={item.id}>{item.nombre}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>

                    {
                        perfil.id === 'administrador' &&
                        <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Label>Col·legi</Form.Label>
                            <Form.Select aria-label="Default select example"
                                value={formValuesSearch.colegio_id}
                                name='colegio_id'
                                onChange={handleInputChangeSearch}>
                                <option></option>
                                {fetchParametersData?.data?.data?.colegios?.map((item) => {
                                    return (
                                        <option key={item.id} value={item.id}>{item.nombre}</option>
                                    )
                                })}
                            </Form.Select>
                        </Form.Group>
                    }


                    <div className='mt-2'>
                        <button className="px-3 btn btn-primary float-end">
                            <FontAwesomeIcon icon={faSearch} /> Cercar
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    )
}
