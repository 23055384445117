import { faArrowDown, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { Button, Form, Modal, Spinner, Table } from 'react-bootstrap'
import { getMsgInputColor, getMsgInputServerColor } from '../../helpers/helpers'
import { useFetch } from '../../hooks/useFetch'
import { Loader } from '../Loader/Loader'
import { MsgInput } from '../MsgInput/MsgInput'
import { MsgInputServer } from '../MsgInput/MsgInputServer'
import './CollegiatsMain.css'

export const ModalEditarColegiado = ({
    showModalEditarColegiado,
    setShowModalEditarColegiado,
    handleCloseModalEditarColegiado,
    formValuesEditarColegiado,
    handleInputChangeEditarColegiado,
    handleFormChangeEditarColegiado,
    resetFormValuesEditarColegiado,
    fetchEditarColegiado,
    fetchEditarColegiadoData,
    fetchParametersData,
    fetchMapaColegios,
    fetchMapaColegiosData,
    fetchCollegiats,
    formValuesSearch,
    valuesValidEditarColegiado,
    handleVerifiedValuesEditarColegiado,
    handleAreAllValuesCorrectEditarColegiado }) => {

    const handleClickEditarColegiado = (e, exceptKeys) => {
        e.preventDefault()

        handleVerifiedValuesEditarColegiado(formValuesEditarColegiado, exceptKeys)

        if (handleAreAllValuesCorrectEditarColegiado()) {
            fetchEditarColegiado({
                body: {
                    ...formValuesEditarColegiado
                }
            })
        }
    }

    // useEffect(() => {
    //     console.log(formValuesEditarColegiado)
    // }, [formValuesEditarColegiado])

    useEffect(() => {

        if (fetchEditarColegiadoData.data?.status === 'Success') {

            handleCloseModalEditarColegiado()

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchCollegiats({
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }

    }, [fetchEditarColegiadoData])

    // Municipios y codigos postales

    // const [fetchMapaColegios, fetchMapaColegiosData] = useFetch('GET', `${process.env.REACT_APP_API_URL}/mapa_colegios`)

    useEffect(() => {

        if (fetchMapaColegiosData.data?.data && fetchMapaColegiosData.data?.data?.length > 0) {

            const array = fetchMapaColegiosData?.data?.data
            // console.log(array)

            let municipio_idTemp = array[0].municipio?.id

            if (formValuesEditarColegiado.municipio_id) {

                for (let i = 0; i < array.length; i++) {
                    if (formValuesEditarColegiado.municipio_id == array[i].municipio?.id) {
                        municipio_idTemp = array[i].municipio?.id
                    }
                }
            }

            handleFormChangeEditarColegiado({
                ...formValuesEditarColegiado,
                municipio_id: municipio_idTemp
            })
        }
    }, [fetchMapaColegiosData])

    useEffect(() => {

        const codigo_postalTemp = formValuesEditarColegiado.codigo_postal

        fetchMapaColegios({
            params: {
                colegio_id: formValuesEditarColegiado.colegio_id,
                codigo_postal: codigo_postalTemp
            }
        })

    }, [formValuesEditarColegiado.codigo_postal])


    return (
        <Modal show={showModalEditarColegiado} onHide={handleCloseModalEditarColegiado} size="lg" id="ModalEditarColegiado">
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Fitxa actualitzar col·legiat</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-4'>

                <div className="row">
                    <div className="col-12">
                        <div className='mt-1 mb-3 text-center'>
                            <h5 className='my-0'>
                                {formValuesEditarColegiado.nif} <span className='fw-light'>-</span> {formValuesEditarColegiado.nombre + ' ' + formValuesEditarColegiado.apellidos} <span className='fw-light'>-</span> {formValuesEditarColegiado.num_colegiado}
                            </h5>
                            <div className='mt-1'>
                                {fetchParametersData?.data?.data?.colegios?.map((item) => {
                                    if (item.id !== formValuesEditarColegiado.colegio_id) {
                                        return null
                                    }
                                    return (
                                        <p className='mb-0 fw-normal' key={item.id}>{item.nombre}</p>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-4 mb-4" controlId="exampleForm.ControlInput4">
                        <Form.Label>
                            Data estat {fetchParametersData?.data?.data?.estados_colegiacion?.map((item) => {
                                if (item.id !== formValuesEditarColegiado.estado_id) {
                                    return null
                                }
                                return (
                                    <span className='mb-0' key={item.id}>{item.nombre}</span>
                                )
                            })}
                        </Form.Label>
                        <Form.Control
                            type="date"
                            onChange={handleInputChangeEditarColegiado}
                            value={formValuesEditarColegiado.fecha_estado}
                            name='fecha_estado'
                            autoComplete="one-time-code"
                            className={`${getMsgInputServerColor(fetchEditarColegiadoData, 'fecha_estado') || getMsgInputColor(valuesValidEditarColegiado, 'fecha_estado') ? 'is-invalid' : ''}`}
                        />
                        <MsgInput obj={valuesValidEditarColegiado} name={'fecha_estado'} />
                        <MsgInputServer obj={fetchEditarColegiadoData?.data} name={'fecha_estado'} />
                    </Form.Group>

                    <Form.Group className="col-12 mb-4" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Observacions (opcional)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            onChange={handleInputChangeEditarColegiado}
                            value={formValuesEditarColegiado.observaciones}
                            name='observaciones'
                            autoComplete="one-time-code"
                        />
                    </Form.Group>


                    {
                        formValuesEditarColegiado.estado_id !== "baja" && <>
                            <h5 className='h6 mt-3 mb-3'>Domicili professional</h5>

                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>Codi postal</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleInputChangeEditarColegiado}
                                    value={formValuesEditarColegiado.codigo_postal}
                                    name='codigo_postal'
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchEditarColegiadoData, 'codigo_postal') || getMsgInputColor(valuesValidEditarColegiado, 'codigo_postal') ? 'is-invalid' : ''}`}
                                />

                                <MsgInput obj={valuesValidEditarColegiado} name={'codigo_postal'} />
                                <MsgInputServer obj={fetchEditarColegiadoData?.data} name={'codigo_postal'} />
                            </Form.Group>

                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput2">
                                <Form.Label>Municipi</Form.Label>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleInputChangeEditarColegiado}
                                    value={formValuesEditarColegiado.municipio_id}
                                    name='municipio_id'
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchEditarColegiadoData, 'municipio_id') || getMsgInputColor(valuesValidEditarColegiado, 'municipio_id') ? 'is-invalid' : ''}`}
                                >
                                    {
                                        fetchMapaColegiosData?.data?.data?.map(item => {
                                            return <option key={item.municipio.id} value={item.municipio.id}>{item.municipio.nombre}</option>
                                        })
                                    }
                                </Form.Select>
                                <MsgInput obj={valuesValidEditarColegiado} name={'municipio_id'} />
                                <MsgInputServer obj={fetchEditarColegiadoData?.data} name={'municipio_id'} />
                            </Form.Group>

                            <Form.Group className="col-12 col-lg-6 mb-4" controlId="exampleForm.ControlInput3">
                                <Form.Label>Adreça</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleInputChangeEditarColegiado}
                                    value={formValuesEditarColegiado.calle}
                                    name='calle'
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchEditarColegiadoData, 'calle') || getMsgInputColor(valuesValidEditarColegiado, 'calle') ? 'is-invalid' : ''}`}
                                />
                                <MsgInput obj={valuesValidEditarColegiado} name={'calle'} />
                                <MsgInputServer obj={fetchEditarColegiadoData?.data} name={'calle'} />
                            </Form.Group>
                        </>
                    }

                </div>

            </Modal.Body>
            <Modal.Footer>

                <Button variant="secondary" onClick={handleCloseModalEditarColegiado}>
                    Sortir
                </Button>
                <Button
                    variant="info"
                    type='submit'
                    className='ms-2'
                    disabled={fetchEditarColegiadoData.loading}
                    onClick={(e) => handleClickEditarColegiado(e, formValuesEditarColegiado.estado_id !== "baja" ? [] : ['codigo_postal', 'municipio_id', 'calle'])}
                >
                    {
                        fetchEditarColegiadoData.loading ?
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className='me-2'
                            />
                            : ''
                    }
                    <FontAwesomeIcon icon={faEdit} className='me-1' /> Actualitzar col·legiat
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
