import React, { useEffect, useState } from 'react'
import { faDownload, faEdit, faSave, faSort, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Form, Modal, OverlayTrigger, Spinner, Table, Tooltip } from 'react-bootstrap'
import { getMsgInputColor, getMsgInputServerColor } from '../../helpers/helpers'
import { useFetch } from '../../hooks/useFetch'
import { useForm } from '../../hooks/useForm'
import { useValidator } from '../../hooks/useValidator'
import { Loader } from '../Loader/Loader'
import { MsgInput } from '../MsgInput/MsgInput'
import { MsgInputServer } from '../MsgInput/MsgInputServer'
import { PaginationT } from '../PaginationT/PaginationT'

export const UsuarisMain = ({ namePage, fetchUsuariosData, fetchUsuarios, handleFormChange, formValuesSearch, fetchParametersData, colegio, perfil }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [action, setAction] = useState('');



    useEffect(() => {
        if (!show) {

            setAction('')

            resetFormValuesCreateUser()

            resetValuesValidIngenierioCreate()
        }
    }, [show])

    const [fetchUsuario, fetchUsuarioData] = useFetch('POST', `${process.env.REACT_APP_API_URL}/usuarios`)

    const [fetchPatchUsuario, fetchPatchUsuarioData] = useFetch('PATCH', `${process.env.REACT_APP_API_URL}/usuarios`)

    const [formValuesCreateUser, handleInputChangeCreateUser, handleFormChangeCreateUser, resetFormValuesCreateUser] = useForm({
        id: '',
        nombre: '',
        apellidos: '',
        telefono: '',
        email: '',
        colegio_id: colegio?.id || 1,
        perfil_id: 'usuario',
        password: '',
        password_confirmation: '',
        fecha_creacion: '',
        fecha_modificacion: ''
    })

    const [valuesValidCreate, handleVerifiedValuesCreate, handleAreAllValuesCorrect, resetValuesValidIngenierioCreate] = useValidator({
        id: {},
        nombre: {
            required: {
                isOk: true,
                msgError: [`El nom és necessari.`]
            }
        },
        apellidos: {
            required: {
                isOk: true,
                msgError: [`Els cognoms són necessaris.`]
            }
        },
        telefono: {
            required: {
                isOk: true,
                msgError: [`El telèfon és requerit`]
            }
        },
        email: {
            required: {
                isOk: true,
                msgError: [`L'email és necessari.`]
            }
        },
        colegio_id: {
            required: {
                isOk: true,
                msgError: [`El col·legi és necessari.`]
            }
        },
        perfil_id: {
            required: {
                isOk: true,
                msgError: [`El perfil és necessari.`]
            }
        },
        password: {
            required: {
                isOk: true,
                msgError: [`La contrasenya és necessària.`]
            }
        },
        password_confirmation: {
            required: {
                isOk: true,
                msgError: [`La confirmació de la contrasenya és necessària.`]
            }
        },
        fecha_creacion: {},
        fecha_modificacion: {}
    })

    const handleSubmitCreateUser = (e) => {

        handleVerifiedValuesCreate(formValuesCreateUser)

        if (valuesValidCreate && handleAreAllValuesCorrect()) {

            if (action === 'CREATE') {

                fetchUsuario({
                    body: formValuesCreateUser
                })
            }

            if (action === 'UPDATE') {

                fetchPatchUsuario({
                    id: formValuesCreateUser.id,
                    body: {
                        ...formValuesCreateUser
                    }
                })
            }
        }
    }

    const handleShowCrearUsuario = (e) => {

        e.preventDefault()

        setAction('CREATE')

        handleShow()
    }

    // Start --- Handle Click Descargar Excel

    const [fetchUsuariosExcel, fetchUsuariosExcelData] = useFetch('GET', `${process.env.REACT_APP_API_URL}/usuarios`, 'download')

    const handleClickDownloadFile = () => {

        let formValuesSearchTemp = { ...formValuesSearch }

        Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

        fetchUsuariosExcel({
            params: {
                ...formValuesSearchTemp,
                to_excel: true
            }
        })
    }

    useEffect(() => {

        if (fetchUsuariosExcelData.data) {
            // 2. Create blob link to download
            const instanceBlob = new Blob([fetchUsuariosExcelData.data])

            const date = new Date()

            const url = window.URL.createObjectURL(instanceBlob);
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `usuaris_${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}.${instanceBlob.type || 'xlsx'}`);
            // 3. Append to html page
            document.body.appendChild(link);

            // 4. Force download
            link.click();

            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);

            fetchUsuariosExcelData.setState({
                loading: false,
                error: null,
                data: null,
            })
        }
    }, [fetchUsuariosExcelData])

    // End -------------

    const handleShowActualizarUsuario = (item) => {

        setAction('UPDATE')

        handleFormChangeCreateUser({
            id: item.id,
            nombre: item.nombre || '',
            apellidos: item.apellidos || '',
            telefono: item.telefono || '',
            email: item.email || '',
            colegio_id: item.colegio?.id || '',
            perfil_id: item.perfil_usuario?.id || '',
            fecha_creacion: item.fecha_creacion || '',
            fecha_modificacion: item.fecha_modificacion || ''
        })

        handleShow()
    };

    useEffect(() => {

        if (fetchUsuarioData.data?.status === 'Success') {

            handleClose()

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchUsuarios({
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }

    }, [fetchUsuarioData])

    useEffect(() => {

        if (fetchPatchUsuarioData.data?.status === 'Success') {

            handleClose()

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchUsuarios({
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }

    }, [fetchPatchUsuarioData])

    return (

        <div className='col-xl-9'>
            <div className="MainInner shadow-sm animate__animated animate__fadeInUp col">
                <div className="d-flex justify-content-between mb-4 pb-2 flex-wrap">
                    <div>
                        <h5 className='mb-3 mb-lg-0'>Llista de {namePage}</h5>
                    </div>
                    <div className='d-flex justify-content-end ms-auto'>
                        {
                            perfil.id === 'administrador' && <Button variant="outline-primary" className="px-3 d-flex align-items-center"
                                onClick={handleClickDownloadFile}
                                disabled={fetchUsuariosExcelData.loading}
                            >
                                {
                                    fetchUsuariosExcelData.loading ?
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className='me-2'
                                        />
                                        : ''
                                }
                                <FontAwesomeIcon icon={faDownload} /> <span className='d-none d-md-block ms-2'>Descarregar</span>
                            </Button>
                        }

                        <Button variant="primary" onClick={handleShowCrearUsuario} className="px-3 ms-3 d-flex align-items-center">
                            <FontAwesomeIcon icon={faUser} className='me-2' /> Alta <span className=''>&nbsp;Usuaris</span>
                        </Button>
                    </div>

                </div>
                {
                    fetchUsuariosData?.loading ?
                        <Loader /> :

                        fetchUsuariosData?.data?.data?.total ?
                            <>

                                <Table hover bordered responsive className='table-fixed-end'>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>
                                                <button
                                                    className={`bg-transparent border-0 text-start w-100 fw-bold ${formValuesSearch?.orderby && formValuesSearch?.orderby?.indexOf('nombre') !== -1 ? 'table-orderby-active' : ''}`}
                                                    onClick={() => {
                                                        if (formValuesSearch.orderby === 'nombre asc') {
                                                            handleFormChange({
                                                                ...formValuesSearch,
                                                                orderby: 'nombre desc'
                                                            });
                                                        } else {
                                                            handleFormChange({
                                                                ...formValuesSearch,
                                                                orderby: 'nombre asc'
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faSort} className='me-2' />
                                                    Nom
                                                </button>
                                            </th>
                                            <th>
                                                <button
                                                    className={`bg-transparent border-0 text-start w-100 fw-bold ${formValuesSearch?.orderby && formValuesSearch?.orderby?.indexOf('apellido') !== -1 ? 'table-orderby-active' : ''}`}
                                                    onClick={() => {
                                                        if (formValuesSearch.orderby === 'apellido asc') {
                                                            handleFormChange({
                                                                ...formValuesSearch,
                                                                orderby: 'apellido desc'
                                                            });
                                                        } else {
                                                            handleFormChange({
                                                                ...formValuesSearch,
                                                                orderby: 'apellido asc'
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faSort} className='me-2' />
                                                    Cognoms
                                                </button>
                                            </th>
                                            <th>Col·legi</th>
                                            <th>
                                                <button
                                                    className={`bg-transparent border-0 text-start w-100 fw-bold ${formValuesSearch?.orderby && formValuesSearch?.orderby?.indexOf('email') !== -1 ? 'table-orderby-active' : ''}`}
                                                    onClick={() => {
                                                        if (formValuesSearch.orderby === 'email asc') {
                                                            handleFormChange({
                                                                ...formValuesSearch,
                                                                orderby: 'email desc'
                                                            });
                                                        } else {
                                                            handleFormChange({
                                                                ...formValuesSearch,
                                                                orderby: 'email asc'
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faSort} className='me-2' />
                                                    E-mail
                                                </button>
                                            </th>
                                            <th>Telèfon</th>
                                            <th>Rol</th>
                                            {/* <th>fecha_creacion</th>
                                            <th>fecha_modificacion</th> */}
                                            <th className='text-center'>Accions</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {fetchUsuariosData?.data?.data?.data?.map((item, key) => {
                                            
                                            return (
                                                <tr key={item.email}>
                                                    <td>{item.id}</td>
                                                    <td>{item.nombre}</td>
                                                    <td>{item.apellidos}</td>
                                                    <td>{item.colegio?.nombre}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.telefono}</td>
                                                    <td>{item.perfil_usuario.nombre}</td>
                                                    {/* <td>{item.fecha_creacion}</td>
                                                    <td>{item.fecha_modificacion}</td> */}

                                                    <td valign='middle' className='text-center'>
                                                        {
                                                            perfil.id === 'administrador' || (perfil.id === 'administrador_colegio' && colegio.id === item.colegio?.id) ?
                                                                <OverlayTrigger
                                                                    key={item.id + '2'}
                                                                    placement={'top'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${item.id}`}>
                                                                            Actualitzar
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Button variant="info"
                                                                        onClick={() => {
                                                                            handleShowActualizarUsuario(item);
                                                                        }}
                                                                        size="sm">
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                    </Button>
                                                                </OverlayTrigger> : null
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </Table>
                                <PaginationT
                                    total={fetchUsuariosData?.data?.data?.total}
                                    handleFormChange={handleFormChange}
                                    formValuesSearch={formValuesSearch}
                                    perPage={fetchUsuariosData?.data?.data?.per_page}
                                />
                            </>
                            :
                            <h5 className='my-0 py-5 text-center'>No es van trobar resultats</h5>
                }
            </div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Alta Usuaris</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-4'>
                    <Form className='row'>
                        <Form.Group className="col-12 col-sm-4 mb-3" controlId="exampleForm.ControlInput7">
                            <Form.Label>Nom*</Form.Label>
                            <Form.Control
                                type="text"
                                name='nombre'
                                value={formValuesCreateUser.nombre}
                                onChange={handleInputChangeCreateUser}
                                autoComplete="one-time-code"
                                className={`${getMsgInputServerColor(fetchUsuarioData, 'nombre') || getMsgInputServerColor(fetchPatchUsuarioData, 'nombre') || getMsgInputColor(valuesValidCreate, 'nombre') ? 'is-invalid' : ''}`}
                            />

                            <MsgInputServer obj={fetchUsuarioData?.data} name={'nombre'} />
                            <MsgInputServer obj={fetchPatchUsuarioData?.data} name={'nombre'} />
                            <MsgInput obj={valuesValidCreate} name={'nombre'} />
                        </Form.Group>

                        <Form.Group className="col-12 col-sm-6 mb-3" controlId="exampleForm.ControlInput7">
                            <Form.Label>Cognoms*</Form.Label>
                            <Form.Control
                                type="text"
                                name='apellidos'
                                value={formValuesCreateUser.apellidos}
                                onChange={handleInputChangeCreateUser}
                                autoComplete="one-time-code"
                                className={`${getMsgInputServerColor(fetchUsuarioData, 'apellidos') || getMsgInputServerColor(fetchPatchUsuarioData, 'apellidos') || getMsgInputColor(valuesValidCreate, 'apellidos') ? 'is-invalid' : ''}`}
                            />
                            <MsgInputServer obj={fetchUsuarioData?.data} name={'apellidos'} />
                            <MsgInputServer obj={fetchPatchUsuarioData?.data} name={'apellidos'} />
                            <MsgInput obj={valuesValidCreate} name={'apellidos'} />
                        </Form.Group>

                        <Form.Group className="col-12 col-sm-5 mb-3" controlId="exampleForm.ControlInput7">
                            <Form.Label>Email*</Form.Label>
                            <Form.Control
                                type="text"
                                name='email'
                                value={formValuesCreateUser.email}
                                onChange={handleInputChangeCreateUser}
                                autoComplete="one-time-code"
                                className={`${getMsgInputServerColor(fetchUsuarioData, 'email') || getMsgInputServerColor(fetchPatchUsuarioData, 'email') || getMsgInputColor(valuesValidCreate, 'email') ? 'is-invalid' : ''}`}
                            />
                            <MsgInputServer obj={fetchUsuarioData?.data} name={'email'} />
                            <MsgInputServer obj={fetchPatchUsuarioData?.data} name={'email'} />
                            <MsgInput obj={valuesValidCreate} name={'email'} />
                        </Form.Group>

                        <Form.Group className="col-12 col-sm-4 mb-3" controlId="exampleForm.ControlInput7">
                            <Form.Label>Telèfon</Form.Label>
                            <Form.Control
                                type="text"
                                name='telefono'
                                value={formValuesCreateUser.telefono}
                                onChange={handleInputChangeCreateUser}
                                autoComplete="one-time-code"
                                className={`${getMsgInputServerColor(fetchUsuarioData, 'telefono') || getMsgInputServerColor(fetchPatchUsuarioData, 'telefono') || getMsgInputColor(valuesValidCreate, 'telefono') ? 'is-invalid' : ''}`}
                            />
                            <MsgInputServer obj={fetchUsuarioData?.data} name={'telefono'} />
                            <MsgInputServer obj={fetchPatchUsuarioData?.data} name={'telefono'} />
                            <MsgInput obj={valuesValidCreate} name={'telefono'} />
                        </Form.Group>
                        <div className="col-12"></div>

                        <Form.Group className="col-12 col-sm-4 mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Label>Col·legi*</Form.Label>
                            <Form.Select aria-label="Default select example"
                                value={formValuesCreateUser.colegio_id}
                                name='colegio_id'
                                onChange={handleInputChangeCreateUser}
                                disabled={perfil.id === 'administrador_colegio'}
                                className={`${getMsgInputServerColor(fetchUsuarioData, 'colegio_id') || getMsgInputServerColor(fetchPatchUsuarioData, 'colegio_id') || getMsgInputColor(valuesValidCreate, 'colegio_id') ? 'is-invalid' : ''}`}
                            >
                                {fetchParametersData?.data?.data?.colegios?.map((item) => {
                                    return (
                                        <option key={item.id} value={item.id}>{item.nombre}</option>
                                    )
                                })}
                            </Form.Select>
                            <MsgInputServer obj={fetchUsuarioData?.data} name={'colegio_id'} />
                            <MsgInputServer obj={fetchPatchUsuarioData?.data} name={'colegio_id'} />
                            <MsgInput obj={valuesValidCreate} name={'colegio_id'} />
                        </Form.Group>

                        <Form.Group className="col-12 col-sm-4 mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Label>Perfil</Form.Label>
                            <Form.Select aria-label="Default select example"
                                value={formValuesCreateUser.perfil_id}
                                name='perfil_id'
                                onChange={handleInputChangeCreateUser}
                                className={`${getMsgInputServerColor(fetchUsuarioData, 'perfil_id') || getMsgInputServerColor(fetchPatchUsuarioData, 'perfil_id') || getMsgInputColor(valuesValidCreate, 'perfil_id') ? 'is-invalid' : ''}`}
                            >
                                {fetchParametersData?.data?.data?.perfiles_usuario?.map((item) => {
                                    if (perfil.id === 'administrador_colegio' && item.id === 'administrador') {
                                        return null
                                    }
                                    return (
                                        <option key={item.id} value={item.id}>{item.nombre}</option>
                                    )
                                })}
                            </Form.Select>
                            <MsgInputServer obj={fetchUsuarioData?.data} name={'perfil_id'} />
                            <MsgInputServer obj={fetchPatchUsuarioData?.data} name={'perfil_id'} />
                            <MsgInput obj={valuesValidCreate} name={'perfil_id'} />
                        </Form.Group>

                        {
                            action === 'CREATE' && <>
                                <div className="col-12 my-2"></div>
                                <Form.Group className="col-12 col-sm-6 mb-3" controlId="exampleForm.ControlInput7">
                                    <Form.Label>Contrasenya*</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name='password'
                                        value={formValuesCreateUser.password}
                                        onChange={handleInputChangeCreateUser}
                                        autoComplete="one-time-code"
                                        className={`${getMsgInputServerColor(fetchUsuarioData, 'password') || getMsgInputServerColor(fetchPatchUsuarioData, 'password') || getMsgInputColor(valuesValidCreate, 'password') ? 'is-invalid' : ''}`}
                                    />
                                    <MsgInputServer obj={fetchUsuarioData?.data} name={'password'} />
                                    <MsgInputServer obj={fetchPatchUsuarioData?.data} name={'password'} />
                                    <MsgInput obj={valuesValidCreate} name={'password'} />
                                </Form.Group>
                                <div className="col-12"></div>

                                <Form.Group className="col-12 col-sm-6 mb-3" controlId="exampleForm.ControlInput7">
                                    <Form.Label>Confirmació de contrasenya*</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name='password_confirmation'
                                        value={formValuesCreateUser.password_confirmation}
                                        onChange={handleInputChangeCreateUser}
                                        autoComplete="one-time-code"
                                        className={`${getMsgInputServerColor(fetchUsuarioData, 'password_confirmation') || getMsgInputServerColor(fetchPatchUsuarioData, 'password_confirmation') || getMsgInputColor(valuesValidCreate, 'password_confirmation') ? 'is-invalid' : ''}`}
                                    />
                                    <MsgInputServer obj={fetchUsuarioData?.data} name={'password_confirmation'} />
                                    <MsgInputServer obj={fetchPatchUsuarioData?.data} name={'password_confirmation'} />
                                    <MsgInput obj={valuesValidCreate} name={'password_confirmation'} />
                                </Form.Group>
                            </>
                        }
                        <div className="col-12"></div>
                        {
                            action === 'UPDATE' &&
                            <>
                                <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput4">
                                    <Form.Label>Registrat des de</Form.Label>
                                    <p>{formValuesCreateUser.fecha_creacion.split(' ')[0].split('-').reverse().join(' / ')}</p>
                                </Form.Group>

                                <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput4">
                                    <Form.Label>Darrera modificació</Form.Label>
                                    <p>{formValuesCreateUser.fecha_modificacion.split(' ')[0].split('-').reverse().join(' / ')}</p>
                                </Form.Group>
                            </>
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel·lar
                    </Button>
                    <Button variant="primary" onClick={handleSubmitCreateUser} className='ms-2' disabled={fetchUsuarioData.loading || fetchPatchUsuarioData.loading}>
                        {
                            fetchUsuarioData.loading || fetchPatchUsuarioData.loading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className='me-2'
                                />
                                : ''
                        }
                        <FontAwesomeIcon icon={faSave} className='me-1' /> {action === 'UPDATE' ? 'Actualitzar' : 'Registrar'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}
