import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, Modal, OverlayTrigger, Spinner, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faDownload, faActualizarUsuario, faSort, faUser, faEdit, faSave, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../Loader/Loader';
import { PaginationT } from '../PaginationT/PaginationT';
import { AuthContext } from '../../auth/AuthContext';
import { useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';
import { useValidator } from '../../hooks/useValidator';
import { MsgInput } from '../MsgInput/MsgInput';
import { MsgInputServer } from '../MsgInput/MsgInputServer';
import { getMsgInputColor, getMsgInputServerColor } from '../../helpers/helpers';
import { ModalDarDeBaja } from './ModalDarDeBaja';
import { ModalEditarColegiado } from './ModalEditarColegiado';
import { ModalDarDeAlta } from './ModalDarDeAlta';

export const CollegiatsMain = ({ fetchCollegiatsData, formValuesSearch, namePage, handleFormChange, fetchParametersData, fetchCollegiats }) => {

    const { user: { colegio, perfil } } = useContext(AuthContext)

    const colegioid = colegio?.id

    const [action, setAction] = useState('');

    // Start - Crear Usuario

    const [showModalCrearUsuario, setShowModalCrearUsuario] = useState(false);

    const handleClose = () => setShowModalCrearUsuario(false);

    useEffect(() => {
        if (!showModalCrearUsuario) {

            setAction('')

            resetFormValuesIngeniero()
            resetFormValuesColegiacion()

            resetValuesValidColegiacion()
            resetValuesValidIngenierio()

        }
    }, [showModalCrearUsuario])

    const handleShowCrearUsuario = () => {

        setAction('CREATE')

        setShowModalCrearUsuario(true)

    };

    // End ----------------

    // Start - Actualizar Usuario

    const handleShowActualizarUsuario = (item, state) => {

        setAction('UPDATE')

        handleFormChangeIngeniero({
            nif: item.ingeniero.nif,
            nombre: item.ingeniero.nombre,
            apellidos: item.ingeniero.apellidos,
            id: item.ingeniero.id
        })

        handleFormChangeColegiacion({
            num_colegiado: item.num_colegiado,
            calle: item.direccion_profesional?.calle || '',
            poblacion: item.direccion_profesional?.poblacion || '',
            codigo_postal: item.direccion_profesional?.codigo_postal || '',
            coordenadas: item.direccion_profesional?.coordenadas || '',
            municipio_id: item.direccion_profesional?.municipio_id || '',
            estado_id: item.estado_colegiacion?.id,
            observaciones: item.observaciones || '',
            ingeniero_id: item.ingeniero.id,
            colegio_id: item.colegio.id,
            fecha_estado: item.fecha_estado,
            fecha_creacion: item.fecha_creacion,
            fecha_modificacion: item.fecha_modificacion
        })

        setShowModalCrearUsuario(true)
    };

    // End --------------

    // Start - ver Historial

    const [showHistorialUsuario, setShowHistorialUsuario] = useState(false);

    const [fetchHistorial, fetchHistorialData] = useFetch('GET', `${process.env.REACT_APP_API_URL}/colegiaciones`)

    const handleCloseHistorialUsuario = () => setShowHistorialUsuario(false);

    const handleShowHistorialUsuario = (id, IDIngeniero, perfilID) => {
        fetchHistorial({
            id
        })
        if (perfilID === 'administrador') {
            fetchIngeniero({
                id: IDIngeniero,
                params: {
                    mostrar_todo: 1
                }
            })
        } else {
            fetchIngeniero({
                id: IDIngeniero
            })
        }

        setShowHistorialUsuario(true)
    };

    // End --------------

    // Start --- Handle Click Descargar Excel

    const [fetchCollegiatsExcel, fetchCollegiatsExcelData] = useFetch('GET', `${process.env.REACT_APP_API_URL}/colegiaciones`, 'download')

    const handleClickDownloadFile = () => {

        let formValuesSearchTemp = { ...formValuesSearch }

        Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

        fetchCollegiatsExcel({
            params: {
                ...formValuesSearchTemp,
                to_excel: true
            }
        })
    }

    useEffect(() => {


        if (fetchCollegiatsExcelData.data) {
            // 2. Create blob link to download
            const instanceBlob = new Blob([fetchCollegiatsExcelData.data])

            const date = new Date()

            const url = window.URL.createObjectURL(instanceBlob);
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `collegiats_${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}.${instanceBlob.type || 'xlsx'}`);
            // 3. Append to html page
            document.body.appendChild(link);

            // 4. Force download
            link.click();

            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);

            fetchCollegiatsExcelData.setState({
                loading: false,
                error: null,
                data: null,
            })
        }
    }, [fetchCollegiatsExcelData])

    // End -------------

    // Obtener ingeniero por ID

    const [fetchIngeniero, fetchIngenieroData] = useFetch('GET', `${process.env.REACT_APP_API_URL}/ingenieros`)

    // Consultar el NIF para CREAR / EDITAR

    const [fetchIngenieroCUIngeniero, fetchIngenieroCUIngenieroData] = useFetch('GET', `${process.env.REACT_APP_API_URL}/ingenieros`)

    const [fetchIngenieroPatch, fetchIngenieroPatchData] = useFetch('PATCH', `${process.env.REACT_APP_API_URL}/ingenieros`)

    const [fetchIngenieroCUColegiacion, fetchIngenieroCUColegiacionData] = useFetch('GET', `${process.env.REACT_APP_API_URL}/colegiaciones`)

    const [fetchCrearCUColegiacion, fetchCrearCUColegiacionData] = useFetch('POST', `${process.env.REACT_APP_API_URL}/colegiaciones`)

    const [formValuesIngeniero, handleInputChangeIngeniero, handleFormChangeIngeniero, resetFormValuesIngeniero] = useForm({
        nif: '',
        nombre: '',
        apellidos: '',
        id: ''
    });

    const [formValuesColegiacion, handleInputChangeColegiacion, handleFormChangeColegiacion, resetFormValuesColegiacion] = useForm({
        num_colegiado: '',
        calle: '',
        poblacion: '',
        codigo_postal: '',
        coordenadas: '',
        municipio_id: '',
        estado_id: 'alta',
        observaciones: '',
        ingeniero_id: '',
        colegio_id: colegioid || 1,
        fecha_estado: '',
        fecha_creacion: ''
    });

    // Buscar ingeniero

    useEffect(() => {

        if (action === 'CREATE') {

            if (formValuesIngeniero?.nif?.length === 9) {

                fetchIngenieroCUIngeniero({
                    params: {
                        nif: formValuesIngeniero.nif
                    }
                })
            } else {
                resetFormValuesColegiacion()
                handleFormChangeIngeniero({
                    nif: formValuesIngeniero.nif,
                    nombre: '',
                    apellidos: '',
                    id: ''
                })
            }
        }

    }, [formValuesIngeniero.nif])

    // Al llegar la respuesta, setea los datos

    useEffect(() => {

        if (fetchIngenieroCUIngenieroData?.data) {
            const ingTemp = fetchIngenieroCUIngenieroData?.data?.data?.data[0]
            if (ingTemp) {
                handleFormChangeIngeniero({
                    nif: ingTemp['nif'],
                    nombre: ingTemp['nombre'],
                    apellidos: ingTemp['apellidos'],
                    id: ingTemp['id']
                })
            } else {
                handleFormChangeIngeniero({
                    nif: formValuesIngeniero.nif,
                    nombre: '',
                    apellidos: '',
                    id: ''
                })
            }
        }

    }, [fetchIngenieroCUIngenieroData])

    // Detecta que al existir un ID de ingeniero, hace la peticion para la colegiacion

    useEffect(() => {

        if (formValuesIngeniero.id && action === 'CREATE') {
            fetchIngenieroCUColegiacion({
                params: {
                    nif: formValuesIngeniero.nif,
                    colegio_id: formValuesColegiacion.colegio_id
                }
            })
        }

    }, [formValuesIngeniero.id, formValuesColegiacion.colegio_id])

    // Al llegar la respuesta, setea los datos de la colegiacion

    useEffect(() => {

        if (fetchIngenieroCUColegiacionData?.data) {

            const colegiacionTemp = fetchIngenieroCUColegiacionData?.data?.data?.data[0]

            if (colegiacionTemp) {
                handleFormChangeColegiacion({
                    num_colegiado: colegiacionTemp.num_colegiado || '',
                    // num_colegiado: formValuesColegiacion.num_colegiado,
                    calle: colegiacionTemp.direccion_profesional?.calle || '',
                    poblacion: colegiacionTemp.direccion_profesional?.poblacion || '',
                    codigo_postal: colegiacionTemp.direccion_profesional?.codigo_postal || '',
                    coordenadas: colegiacionTemp.direccion_profesional?.coordenadas || '',
                    municipio_id: colegiacionTemp.direccion_profesional?.municipio_id || '',
                    estado_id: colegiacionTemp.estado_colegiacion?.id || 'alta',
                    observaciones: colegiacionTemp.observaciones || '',
                    ingeniero_id: colegiacionTemp.ingeniero?.id || '',
                    // colegio_id: colegiacionTemp.colegio?.id || '',
                    colegio_id: formValuesColegiacion.colegio_id,
                    fecha_estado: colegiacionTemp.fecha_estado || '',
                    fecha_creacion: colegiacionTemp.fecha_creacion || '',
                    fecha_modificacion: colegiacionTemp.fecha_modificacion || ''
                })
            } else {
                handleFormChangeColegiacion({
                    num_colegiado: '',
                    calle: '',
                    poblacion: '',
                    codigo_postal: '',
                    coordenadas: '',
                    municipio_id: '',
                    estado_id: 'alta',
                    observaciones: '',
                    ingeniero_id: '',
                    colegio_id: formValuesColegiacion.colegio_id,
                    fecha_estado: '',
                    fecha_creacion: '',
                    fecha_modificacion: ''
                })
            }
        }

    }, [fetchIngenieroCUColegiacionData])

    // Evento de click en el guardar

    const handleClickEnviarDatos = (e) => {

        e.preventDefault()

        handleVerifiedValuesColegiacion(formValuesColegiacion)
        handleVerifiedValuesIngenierio(formValuesIngeniero)

        const allValueOk = valuesValidColegiacion && handleAreAllValuesCorrectColegiacion() && valuesValidIngenierio && handleAreAllValuesCorrectIngenierio()
        if (formValuesIngeniero.id) {

            if (allValueOk) {

                fetchIngenieroPatch({
                    id: formValuesIngeniero.id,
                    body: {
                        ...formValuesIngeniero
                    }
                })

                fetchCrearCUColegiacion({
                    body: {
                        ...formValuesColegiacion,
                        num_colegiado: formValuesColegiacion.num_colegiado + '',
                        ingeniero_id: formValuesIngeniero.id + '',
                        estado_id: formValuesColegiacion.estado_id
                    }
                })


            }
        } else {

            if (allValueOk) {

                fetchIngenieroNuevo({
                    body: {
                        nombre: formValuesIngeniero.nombre + '',
                        apellidos: formValuesIngeniero.apellidos + '',
                        nif: formValuesIngeniero.nif + ''
                    }
                })
            }
        }
    }

    // use validator para la ingeniero

    const [valuesValidIngenierio, handleVerifiedValuesIngenierio, handleAreAllValuesCorrectIngenierio, resetValuesValidIngenierio] = useValidator({
        nombre: {
            required: {
                isOk: true,
                msgError: [`El camp nom és obligatori.`]
            }
        },
        apellidos: {
            required: {
                isOk: true,
                msgError: [`El camp cognoms és obligatori.`]
            }
        },
        nif: {
            required: {
                isOk: true,
                msgError: [`El camp nif és obligatori.`]
            }
        },
    })

    // use validator para la colegiacion (Admin)

    const [valuesValidColegiacion, handleVerifiedValuesColegiacion, handleAreAllValuesCorrectColegiacion, resetValuesValidColegiacion] = useValidator({
        num_colegiado: {
            required: {
                isOk: true,
                msgError: [`El camp num colegiado és obligatori.`]
            }
        },
        calle: {},
        poblacion: {},
        codigo_postal: {},
        coordenadas: {},
        municipio_id: {},
        estado_id: {
            required: {
                isOk: true,
                msgError: [`El camp estado id és obligatori.`]
            }
        },
        observaciones: {},
        ingeniero_id: {},
        colegio_id: {
            required: {
                isOk: true,
                msgError: [`El camp col·legi és obligatori.`]
            }
        },
        fecha_estado: {
            required: {
                isOk: true,
                msgError: [`El camp fecha estado és obligatori.`]
            }
        }
    })

    // Si no existe el Ingeniero, Crearlo primero

    const [fetchIngenieroNuevo, fetchIngenieroNuevoData] = useFetch('POST', `${process.env.REACT_APP_API_URL}/ingenieros`)

    useEffect(() => {

        if (fetchIngenieroNuevoData.data?.data) {

            fetchCrearCUColegiacion({
                body: {
                    ...formValuesColegiacion,
                    num_colegiado: formValuesColegiacion.num_colegiado + '',
                    ingeniero_id: fetchIngenieroNuevoData.data.data.id + '',
                    estado_id: formValuesColegiacion.estado_id
                }
            })
        }

    }, [fetchIngenieroNuevoData])

    // Municipios y codigos postales

    const [fetchMapaColegios, fetchMapaColegiosData] = useFetch('GET', `${process.env.REACT_APP_API_URL}/mapa_colegios`)

    useEffect(() => {

        if (fetchMapaColegiosData.data?.data && fetchMapaColegiosData.data?.data?.length > 0) {

            const array = fetchMapaColegiosData?.data?.data
            // console.log(array)

            let municipio_idTemp = array[0].municipio?.id

            if (formValuesColegiacion.municipio_id) {

                for (let i = 0; i < array.length; i++) {
                    if (formValuesColegiacion.municipio_id == array[i].municipio?.id) {
                        municipio_idTemp = array[i].municipio?.id
                    }
                }
            }

            handleFormChangeColegiacion({
                ...formValuesColegiacion,
                municipio_id: municipio_idTemp
            })
        }
    }, [fetchMapaColegiosData])

    useEffect(() => {

        const codigo_postalTemp = formValuesColegiacion.codigo_postal

        fetchMapaColegios({
            params: {
                colegio_id: formValuesColegiacion.colegio_id,
                codigo_postal: codigo_postalTemp
            }
        })

    }, [formValuesColegiacion.codigo_postal])


    // Alert si salio bien o mal la operacion

    useEffect(() => {

        if (fetchCrearCUColegiacionData.data?.status === 'Success') {

            setShowModalCrearUsuario(false)

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchCollegiats({
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }

    }, [fetchCrearCUColegiacionData])

    // ------------- Dar de baja ----------------

    // Modal dar de Baja

    const [showModalDarDeBaja, setShowModalDarDeBaja] = useState(false);

    const handleCloseModalDarDeBaja = () => {
        setShowModalDarDeBaja(false)
        resetValuesValidDarDeBaja()
        resetFormValuesDarDeBaja()
    };

    const handleShowModalDarDeBaja = (colegiado) => {

        handleFormChangeDarDeBaja({
            estado_id: 'baja',
            ingeniero_id: colegiado.ingeniero?.id,
            colegio_id: colegiado.colegio?.id,
            nif: colegiado.ingeniero?.nif,
            nombre: colegiado.ingeniero?.nombre,
            apellidos: colegiado.ingeniero?.apellidos,
            fecha_estado: '',
            observaciones: '',

            calle: colegiado.direccion_profesional?.calle,
            codigo_postal: colegiado.direccion_profesional?.codigo_postal,
            coordenadas: colegiado.direccion_profesional?.coordenadas,
            municipio_id: colegiado.direccion_profesional?.municipio_id,
            poblacion: colegiado.direccion_profesional?.poblacion,

            num_colegiado: colegiado.num_colegiado
        })

        setShowModalDarDeBaja(true)
    };

    const [fetchDarDeBaja, fetchDarDeBajaData] = useFetch('POST', `${process.env.REACT_APP_API_URL}/colegiaciones`)

    // Formulario dar de Baja

    const [formValuesDarDeBaja, handleInputChangeDarDeBaja, handleFormChangeDarDeBaja, resetFormValuesDarDeBaja] = useForm({
        estado_id: 'baja',
        ingeniero_id: '',
        colegio_id: '',
        nif: '',
        nombre: '',
        apellidos: '',
        fecha_estado: '',
        observaciones: '',

        calle: '',
        poblacion: '',
        codigo_postal: '',
        coordenadas: '',
        municipio_id: '',

        num_colegiado: ''
    });

    // use validator para la baja del colegiado

    const [valuesValidDarDeBaja, handleVerifiedValuesDarDeBaja, handleAreAllValuesCorrectDarDeBaja, resetValuesValidDarDeBaja] = useValidator({
        estado_id: {},
        ingeniero_id: {},
        colegio_id: {},
        nif: {},
        nombre: {},
        apellidos: {},
        fecha_estado: {
            required: {
                isOk: true,
                msgError: [`El camp fecha estado és obligatori.`]
            }
        },
        observaciones: {},

        calle: {},
        poblacion: {},
        codigo_postal: {},
        coordenadas: {},
        municipio_id: {},

        num_colegiado: {}
    })


    // ------------- Editar colegiado ----------------

    const [showModalEditarColegiado, setShowModalEditarColegiado] = useState(false);

    const handleCloseModalEditarColegiado = () => {
        setShowModalEditarColegiado(false)
        resetFormValuesEditarColegiado()
        resetValuesValidEditarColegiado()
    };

    const handleShowModalEditarColegiado = (colegiado) => {

        console.log(colegiado)
        handleFormChangeEditarColegiado({
            estado_id: colegiado.estado_colegiacion?.id,
            ingeniero_id: colegiado.ingeniero?.id,
            colegio_id: colegiado.colegio?.id,
            nif: colegiado.ingeniero?.nif,
            nombre: colegiado.ingeniero?.nombre,
            apellidos: colegiado.ingeniero?.apellidos,
            fecha_estado: colegiado.fecha_estado,
            observaciones: colegiado.observaciones,            
            // observaciones: '',

            calle: colegiado.direccion_profesional?.calle || '',
            poblacion: colegiado.direccion_profesional?.municipio_id || '',
            codigo_postal: colegiado.direccion_profesional?.codigo_postal || '',
            coordenadas: colegiado.direccion_profesional?.coordenadas || '',
            municipio_id: colegiado.direccion_profesional?.municipio_id || '',

            num_colegiado: colegiado.num_colegiado || ''
        })

        setShowModalEditarColegiado(true)
    };

    const [fetchEditarColegiado, fetchEditarColegiadoData] = useFetch('POST', `${process.env.REACT_APP_API_URL}/colegiaciones`)

    // Formulario editar colegiado

    const [formValuesEditarColegiado, handleInputChangeEditarColegiado, handleFormChangeEditarColegiado, resetFormValuesEditarColegiado] = useForm({
        estado_id: '',
        ingeniero_id: '',
        colegio_id: '',
        nif: '',
        nombre: '',
        apellidos: '',
        fecha_estado: '',
        observaciones: '',

        calle: '',
        poblacion: '',
        codigo_postal: '',
        coordenadas: '',
        municipio_id: '',

        num_colegiado: ''
    });

    // use validator para la edicion del colegiado

    const [valuesValidEditarColegiado, handleVerifiedValuesEditarColegiado, handleAreAllValuesCorrectEditarColegiado, resetValuesValidEditarColegiado] = useValidator({
        estado_id: {},
        ingeniero_id: {},
        colegio_id: {},
        nif: {},
        nombre: {},
        apellidos: {},
        observaciones: {},
        fecha_estado: {
            required: {
                isOk: true,
                msgError: [`El camp fecha estado és obligatori.`]
            }
        },

        calle: {},
        poblacion: {},
        codigo_postal: {},
        coordenadas: {},
        municipio_id: {},

        num_colegiado: {},

    })


    // ------------- Dar de alta Colegiado ----------------

    const [showModalDarDeAlta, setShowModalDarDeAlta] = useState(false);

    const handleCloseModalDarDeAlta = () => {
        setShowModalDarDeAlta(false)
        resetFormValuesDarDeAlta()
        setExisteNIF('init')
        setExisteNIFColegio('init')
        setExisteNIFColegioBaja('init')

        resetValuesValidNIF()
        resetValuesValidNoExisteNIF()
        resetValuesValidActivar()
        resetValuesValidCrearColegiado()
    };

    const handleOpenModalDarDeAlta = () => {
        setShowModalDarDeAlta(true)
    }

    // Formulario Crear colegiado

    const [formValuesDarDeAlta, handleInputChangeDarDeAlta, handleFormChangeDarDeAlta, resetFormValuesDarDeAlta] = useForm({
        estado_id: 'alta',
        ingeniero_id: '',
        colegio_id: colegio?.id || 1,
        nif: '',
        nombre: '',
        apellidos: '',
        observaciones: '',
        fecha_estado: '',

        calle: '',
        poblacion: '',
        codigo_postal: '',
        coordenadas: '',
        municipio_id: '',
        
        num_colegiado: '',
        colegiaciones_actuales: [],

    });

    // use validator para el ingreso solo del NIF

    const [valuesValidNIF, handleVerifiedValuesNIF, handleAreAllValuesCorrectNIF, resetValuesValidNIF] = useValidator({
        nif: {
            required: {
                isOk: true,
                msgError: [`El NIF és obligatori`]
            }
        }
    })

    // use validator para crear un nuevo ingeniero

    const [valuesValidNoExisteNIF, handleVerifiedValuesNoExisteNIF, handleAreAllValuesCorrectNoExisteNIF, resetValuesValidNoExisteNIF] = useValidator({
        nif: {
            required: {
                isOk: true,
                msgError: [`El NIF és obligatori`]
            }
        },
        nombre: {
            required: {
                isOk: true,
                msgError: [`El nom és obligatori`]
            }
        },
        apellidos: {
            required: {
                isOk: true,
                msgError: [`Els cognoms són obligatoris`]
            }
        }
    })

    // use validator para activar colegiado

    const [valuesValidActivar, handleVerifiedValuesActivar, handleAreAllValuesCorrectActivar, resetValuesValidActivar] = useValidator({
        estado_id: {
            required: {
                isOk: true,
                msgError: [`L'estat és obligatori`]
            }
        },
        fecha_estado: {
            required: {
                isOk: true,
                msgError: [`La data d'estat és obligatòria`]
            }
        },
        codigo_postal:{},
        municipio_id: {},
        calle: {}
    })

    // use validator para crear colegiado

    const [valuesValidCrearColegiado, handleVerifiedValuesCrearColegiado, handleAreAllValuesCorrectCrearColegiado, resetValuesValidCrearColegiado] = useValidator({
        fecha_estado: {
            required: {
                isOk: true,
                msgError: [`La data d'estat és obligatòria`]
            }
        },
        codigo_postal:{},
        municipio_id: {},
        calle: {}
    })
    

    // Sub variables de Dar de Alta a colegiado

    const [existeNIF, setExisteNIF] = useState('init');
    const [existeNIFColegio, setExisteNIFColegio] = useState('init');
    const [existeNIFColegioBaja, setExisteNIFColegioBaja] = useState('init');

    const [fetchDarDeAltaExisteNIF, fetchDarDeAltaExisteNIFData] = useFetch('GET', `${process.env.REACT_APP_API_URL}/ingenieros`)
    const [fetchDarDeAltaExisteNIFColegio, fetchDarDeAltaExisteNIFColegioData] = useFetch('GET', `${process.env.REACT_APP_API_URL}/colegiaciones`)

    const [fetchDarDeAltaCrearIngeniero, fetchDarDeAltaCrearIngenieroData] = useFetch('POST', `${process.env.REACT_APP_API_URL}/ingenieros`)
    const [fetchDarDeAltaCrearColegiado, fetchDarDeAltaCrearColegiadoData] = useFetch('POST', `${process.env.REACT_APP_API_URL}/colegiaciones`)

    return (
        <div className="col-xl-9">
            <div className="MainInner shadow-sm animate__animated animate__fadeInUp d-flex flex-column w-auto">

                <div className="d-flex justify-content-between mb-4 pb-2 flex-wrap">
                    <div>
                        <h5 className='mb-3 mb-lg-0'>Llista de {namePage}</h5>
                    </div>
                    <div className='d-flex justify-content-end ms-auto'>
                        {perfil.id === 'administrador' && <Button variant="outline-primary" className="px-3 d-flex align-items-center" onClick={
                            () => handleClickDownloadFile()
                        } disabled={fetchCollegiatsExcelData.loading}>
                            {
                                fetchCollegiatsExcelData.loading ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className='me-2'
                                    />
                                    : ''
                            }
                            <FontAwesomeIcon icon={faDownload} /> <span className='d-none d-md-block ms-2'>Descarregar</span>
                        </Button>}
                        <Button variant="primary" className="px-3 ms-3 d-flex align-items-center"
                            onClick={() => {
                                if (perfil.id !== 'administrador') {
                                    handleOpenModalDarDeAlta()
                                } else {
                                    handleShowCrearUsuario()
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faUser} className='me-2' /> Alta <span className=''>&nbsp;Col·legiat</span>
                        </Button>
                    </div>
                </div>

                <Modal show={showModalCrearUsuario} onHide={handleClose} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title className='h5'> {action === 'UPDATE' ? 'Actualitzar' : 'Alta'} Col·legiat</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-4'>
                        <Form className='row'>

                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput7">
                                <Form.Label>NIF / NIE</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleInputChangeIngeniero}
                                    value={formValuesIngeniero.nif}
                                    name='nif'
                                    autoComplete="one-time-code"
                                    disabled={action === 'UPDATE'}
                                    className={`${getMsgInputServerColor(fetchIngenieroNuevoData, 'nif') || getMsgInputColor(valuesValidIngenierio, 'nif') ? 'is-invalid' : ''}`}
                                />
                                <MsgInput obj={valuesValidIngenierio} name={'nif'} />
                                <MsgInputServer obj={fetchIngenieroNuevoData?.data} name={'nif'} />
                            </Form.Group>

                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput7">
                                <Form.Label>Nom</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleInputChangeIngeniero}
                                    value={formValuesIngeniero.nombre}
                                    name='nombre'
                                    disabled={(fetchIngenieroCUIngenieroData?.data && formValuesIngeniero.nif?.length === 9) || action === 'UPDATE' ? false : true}
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchIngenieroNuevoData, 'nombre') || getMsgInputColor(valuesValidIngenierio, 'nombre') ? 'is-invalid' : ''}`}
                                />
                                <MsgInput obj={valuesValidIngenierio} name={'nombre'} />
                                <MsgInputServer obj={fetchIngenieroNuevoData?.data} name={'nombre'} />
                            </Form.Group>

                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput7">
                                <Form.Label>Cognoms</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleInputChangeIngeniero}
                                    value={formValuesIngeniero.apellidos}
                                    name='apellidos'
                                    disabled={(fetchIngenieroCUIngenieroData?.data && formValuesIngeniero.nif?.length === 9) || action === 'UPDATE' ? false : true}
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchIngenieroNuevoData, 'apellidos') || getMsgInputColor(valuesValidIngenierio, 'apellidos') ? 'is-invalid' : ''}`}
                                />
                                <MsgInput obj={valuesValidIngenierio} name={'apellidos'} />
                                <MsgInputServer obj={fetchIngenieroNuevoData?.data} name={'apellidos'} />
                            </Form.Group>

                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <Form.Control
                                    type="hidden"
                                    onChange={handleInputChangeIngeniero}
                                    value={formValuesIngeniero.id}
                                    name='id'
                                    disabled={(fetchIngenieroCUIngenieroData?.data && formValuesIngeniero.id?.length === 9) || action === 'UPDATE' ? false : true}
                                    autoComplete="one-time-code"
                                />
                            </Form.Group>

                            <div className="col-12 pb-3">
                                <hr className="dropdown-divider" />
                            </div>

                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput2">
                                <Form.Label>Col·legi</Form.Label>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleInputChangeColegiacion}
                                    value={formValuesColegiacion.colegio_id}
                                    name='colegio_id'
                                    disabled={perfil.id !== 'administrador'}
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchCrearCUColegiacionData, 'colegio_id') || getMsgInputColor(valuesValidColegiacion, 'colegio_id') ? 'is-invalid' : ''}`}
                                >
                                    {fetchParametersData?.data?.data?.colegios?.map((item) => {
                                        return (
                                            <option key={item.id} value={item.id} >{item.nombre}</option>
                                        )
                                    })}
                                </Form.Select>
                                <MsgInput obj={valuesValidColegiacion} name={'colegio_id'} />
                                <MsgInputServer obj={fetchCrearCUColegiacionData?.data} name={'colegio_id'} />
                            </Form.Group>

                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput7">
                                <Form.Label>N° col·legiat</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleInputChangeColegiacion}
                                    value={formValuesColegiacion.num_colegiado}
                                    name='num_colegiado'
                                    autoComplete="one-time-code"
                                    disabled={(fetchIngenieroCUIngenieroData?.data && (fetchIngenieroCUColegiacionData?.data || !formValuesIngeniero.id) && formValuesIngeniero.nif?.length === 9) || action === 'UPDATE' ? false : true}
                                    className={`${getMsgInputServerColor(fetchCrearCUColegiacionData, 'num_colegiado') || getMsgInputColor(valuesValidColegiacion, 'num_colegiado') ? 'is-invalid' : ''}`}
                                />
                                <MsgInput obj={valuesValidColegiacion} name={'num_colegiado'} />
                                <MsgInputServer obj={fetchCrearCUColegiacionData?.data} name={'num_colegiado'} />
                            </Form.Group>

                            {/* <div className="col-s12"></div> */}

                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput6">
                                <Form.Label>Estat</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    onChange={handleInputChangeColegiacion}
                                    value={formValuesColegiacion.estado_id}
                                    name='estado_id'
                                    // disabled={fetchIngenieroCUColegiacionData?.loading}
                                    // disabled={(fetchIngenieroCUIngenieroData?.data && fetchIngenieroCUColegiacionData.data != null && formValuesIngeniero.nif?.length === 9) || action === 'UPDATE' ? false : true}
                                    disabled={(fetchIngenieroCUIngenieroData?.data && (fetchIngenieroCUColegiacionData?.data || !formValuesIngeniero.id) && formValuesIngeniero.nif?.length === 9) || action === 'UPDATE' ? false : true}
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchCrearCUColegiacionData, 'estado_id') || getMsgInputColor(valuesValidColegiacion, 'estado_id') ? 'is-invalid' : ''}`}
                                >
                                    <option value="alta">Alta</option>
                                    <option value="baja">Baixa</option>
                                    <option value="reingres_mateix">Reingrés Mateix</option>
                                    <option value="reingres_nou">Reingrés Nou</option>
                                </Form.Select>
                                <MsgInput obj={valuesValidColegiacion} name={'estado_id'} />
                                <MsgInputServer obj={fetchCrearCUColegiacionData?.data} name={'estado_id'} />
                            </Form.Group>

                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput4">
                                <Form.Label>Data estat</Form.Label>
                                <Form.Control
                                    type="date"
                                    onChange={handleInputChangeColegiacion}
                                    value={formValuesColegiacion.fecha_estado}
                                    name='fecha_estado'
                                    // disabled={fetchIngenieroCUColegiacionData?.loading} 
                                    disabled={(fetchIngenieroCUIngenieroData?.data && (fetchIngenieroCUColegiacionData?.data || !formValuesIngeniero.id) && formValuesIngeniero.nif?.length === 9) || action === 'UPDATE' ? false : true}
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchCrearCUColegiacionData, 'fecha_estado') || getMsgInputColor(valuesValidColegiacion, 'fecha_estado') ? 'is-invalid' : ''}`}
                                />
                                <MsgInput obj={valuesValidColegiacion} name={'fecha_estado'} />
                                <MsgInputServer obj={fetchCrearCUColegiacionData?.data} name={'fecha_estado'} />
                            </Form.Group>

                            <div className="col-s12"></div>

                            <Form.Group className="col-12 col-xl-6 mb-4" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Observacions (opcional)</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    onChange={handleInputChangeColegiacion}
                                    value={formValuesColegiacion.observaciones}
                                    name='observaciones'
                                    // disabled={fetchIngenieroCUColegiacionData?.loading}
                                    disabled={(fetchIngenieroCUIngenieroData?.data && (fetchIngenieroCUColegiacionData?.data || !formValuesIngeniero.id) && formValuesIngeniero.nif?.length === 9) || action === 'UPDATE' ? false : true}
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchCrearCUColegiacionData, 'observaciones') || getMsgInputColor(valuesValidColegiacion, 'observaciones') ? 'is-invalid' : ''}`}
                                />
                                <MsgInput obj={valuesValidColegiacion} name={'observaciones'} />
                                <MsgInputServer obj={fetchCrearCUColegiacionData?.data} name={'observaciones'} />
                            </Form.Group>

                            {formValuesColegiacion.fecha_creacion && <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput4">
                                <Form.Label>Registrat des de</Form.Label>
                                <p>{formValuesColegiacion.fecha_creacion.split(' ')[0].split('-').reverse().join(' / ')}</p>
                            </Form.Group>}

                            {formValuesColegiacion.fecha_modificacion && <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput4">
                                <Form.Label>Darrera modificació</Form.Label>
                                <p>{formValuesColegiacion.fecha_modificacion.split(' ')[0].split('-').reverse().join(' / ')}</p>

                            </Form.Group>}

                            <h5 className='h6 mt-3 mb-3'>Domicili professional</h5>

                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>Codi postal</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleInputChangeColegiacion}
                                    value={formValuesColegiacion.codigo_postal}
                                    name='codigo_postal'
                                    // disabled={fetchIngenieroCUColegiacionData?.loading}
                                    disabled={(fetchIngenieroCUIngenieroData?.data && (fetchIngenieroCUColegiacionData?.data || !formValuesIngeniero.id) && formValuesIngeniero.nif?.length === 9) || action === 'UPDATE' ? false : true}
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchCrearCUColegiacionData, 'codigo_postal') || getMsgInputColor(valuesValidColegiacion, 'codigo_postal') ? 'is-invalid' : ''}`}
                                />

                                <MsgInput obj={valuesValidColegiacion} name={'codigo_postal'} />
                                <MsgInputServer obj={fetchCrearCUColegiacionData?.data} name={'codigo_postal'} />
                            </Form.Group>

                            <Form.Group className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" controlId="exampleForm.ControlInput2">
                                <Form.Label>Municipi</Form.Label>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleInputChangeColegiacion}
                                    value={formValuesColegiacion.municipio_id}
                                    name='municipio_id'
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchCrearCUColegiacionData, 'municipio_id') || getMsgInputColor(valuesValidColegiacion, 'municipio_id') ? 'is-invalid' : ''}`}
                                >
                                    {
                                        fetchMapaColegiosData?.data?.data?.map(item => {
                                            return <option key={item.municipio.id} value={item.municipio.id}>{item.municipio.nombre}</option>
                                        })
                                    }
                                </Form.Select>
                                <MsgInput obj={valuesValidColegiacion} name={'municipio_id'} />
                                <MsgInputServer obj={fetchCrearCUColegiacionData?.data} name={'municipio_id'} />
                            </Form.Group>

                            <Form.Group className="col-12 col-lg-6 mb-4" controlId="exampleForm.ControlInput3">
                                <Form.Label>Adreça</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleInputChangeColegiacion}
                                    value={formValuesColegiacion.calle}
                                    name='calle'
                                    disabled={(fetchIngenieroCUIngenieroData?.data && (fetchIngenieroCUColegiacionData?.data || !formValuesIngeniero.id) && formValuesIngeniero.nif?.length === 9) || action === 'UPDATE' ? false : true}
                                    autoComplete="one-time-code"
                                    className={`${getMsgInputServerColor(fetchCrearCUColegiacionData, 'calle') || getMsgInputColor(valuesValidColegiacion, 'calle') ? 'is-invalid' : ''}`}
                                />
                                <MsgInput obj={valuesValidColegiacion} name={'calle'} />
                                <MsgInputServer obj={fetchCrearCUColegiacionData?.data} name={'calle'} />
                            </Form.Group>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            handleClose()
                        }}>
                            Cancel·lar
                        </Button>
                        <Button
                            variant="primary"
                            type='submit'
                            className='ms-2'
                            disabled={fetchCrearCUColegiacionData.loading || fetchIngenieroPatchData.loading || fetchIngenieroNuevoData.loading}
                            onClick={handleClickEnviarDatos}
                        >
                            {
                                fetchCrearCUColegiacionData.loading || fetchIngenieroPatchData.loading || fetchIngenieroNuevoData.loading ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className='me-2'
                                    />
                                    : ''
                            }
                            <FontAwesomeIcon icon={faSave} className='me-1' /> {action === 'UPDATE' ? 'Actualitzar' : 'Registrar'}
                        </Button>
                    </Modal.Footer>
                </Modal>
                {
                    fetchCollegiatsData?.loading ?
                        <Loader /> :
                        fetchCollegiatsData?.data?.data?.total ?
                        // fetchCollegiatsData?.data?.data ?
                        <>
                                
                                <Table hover bordered responsive className='table-fixed-end'>
                                    <thead>
                                        <tr>
                                            {/* <th className='text-nowrap'>
                                                ID
                                            </th> */}
                                            <th className='text-nowrap'>
                                                <button
                                                    className={`bg-transparent border-0 text-start w-100 fw-bold ${formValuesSearch?.orderby && formValuesSearch?.orderby?.indexOf('num_colegiado') !== -1 ? 'table-orderby-active' : ''}`}
                                                    onClick={() => {
                                                        if (formValuesSearch.orderby === 'num_colegiado asc') {
                                                            handleFormChange({
                                                                ...formValuesSearch,
                                                                orderby: 'num_colegiado desc'
                                                            });
                                                        } else {
                                                            handleFormChange({
                                                                ...formValuesSearch,
                                                                orderby: 'num_colegiado asc'
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faSort} className='me-2' /> Núm. Col·legiat
                                                </button>

                                            </th>
                                            <th className='text-nowrap'>
                                                <button
                                                    className={`bg-transparent border-0 text-start w-100 fw-bold ${formValuesSearch?.orderby && formValuesSearch?.orderby?.indexOf('nif') !== -1 ? 'table-orderby-active' : ''}`}
                                                    onClick={() => {
                                                        if (formValuesSearch.orderby === 'nif asc') {
                                                            handleFormChange({
                                                                ...formValuesSearch,
                                                                orderby: 'nif desc'
                                                            });
                                                        } else {
                                                            handleFormChange({
                                                                ...formValuesSearch,
                                                                orderby: 'nif asc'
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faSort} className='me-2' />
                                                    NIF
                                                </button>
                                            </th>

                                            <th className='text-nowrap'>Nom i cognoms</th>
                                            <th className='text-nowrap'>Col·legi</th>
                                            <th className='text-nowrap'>Estat</th>
                                            <th className='text-nowrap'>
                                                <button
                                                    className={`bg-transparent border-0 text-start w-100 fw-bold ${formValuesSearch?.orderby && formValuesSearch?.orderby?.indexOf('fecha_estado') !== -1 ? 'table-orderby-active' : ''}`}
                                                    onClick={() => {
                                                        if (formValuesSearch.orderby === 'fecha_estado asc') {
                                                            handleFormChange({
                                                                ...formValuesSearch,
                                                                orderby: 'fecha_estado desc'
                                                            });
                                                        } else {
                                                            handleFormChange({
                                                                ...formValuesSearch,
                                                                orderby: 'fecha_estado asc'
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faSort} className='me-2' />
                                                    Data estat
                                                </button>
                                            </th>
                                            <th className='text-center'>Accions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fetchCollegiatsData?.data?.data?.data?.map((item) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td valign='middle'>{item.num_colegiado}</td>
                                                    {/* <td valign='middle'>{item.ingeniero.id}</td> */}
                                                    <td valign='middle'>{item.ingeniero.nif}</td>
                                                    <td valign='middle'>{item.ingeniero.nombre} {item.ingeniero.apellidos}</td>
                                                    <td valign='middle'>{item.colegio.nombre}</td>
                                                    <td valign='middle'>{item.estado_colegiacion.nombre}</td>
                                                    <td valign='middle' className='text-nowrap'>{item.fecha_estado}</td>
                                                    <td valign='middle' className='text-center'>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <OverlayTrigger
                                                                            key={item.id + '1'}
                                                                            placement={'top'}
                                                                            overlay={
                                                                                <Tooltip id={`tooltip-${item.id}`}>
                                                                                    Històric
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <Button variant="secondary" size="sm"
                                                                                onClick={() => {
                                                                                    handleShowHistorialUsuario(item.id, item.ingeniero.id, perfil.id);
                                                                                }}
                                                                            >
                                                                                <FontAwesomeIcon icon={faBook} />
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    </td>
                                                                    {
                                                                        (perfil.id === 'administrador' || colegio?.id === item.colegio.id) && <td>
                                                                            <OverlayTrigger
                                                                                key={item.id + '2'}
                                                                                placement={'top'}
                                                                                overlay={
                                                                                    <Tooltip id={`tooltip-${item.id}`}>
                                                                                        Actualitzar
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <Button className='ms-1' variant="info"
                                                                                    onClick={() => {
                                                                                        if (perfil.id !== 'administrador') {
                                                                                            handleShowModalEditarColegiado(item)
                                                                                        } else {
                                                                                            handleShowActualizarUsuario(item, 'update');
                                                                                        }
                                                                                    }}
                                                                                    size="sm">
                                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                        </td>
                                                                    }

                                                                    {
                                                                        (item.estado_colegiacion.tipo === 'alta' && (colegio?.id === item.colegio.id || perfil.id === 'administrador')) && <td>
                                                                            <OverlayTrigger
                                                                                key={item.id + '3'}
                                                                                placement={'top'}
                                                                                overlay={
                                                                                    <Tooltip id={`tooltip-${item.id}`}>
                                                                                        Donar de baixa
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <Button className='ms-1' variant="danger"
                                                                                    onClick={() => {
                                                                                        handleShowModalDarDeBaja(item);
                                                                                    }}
                                                                                    size="sm">
                                                                                    <FontAwesomeIcon icon={faArrowDown} />
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            )
                                        })}


                                    </tbody>
                                </Table>
                                <PaginationT
                                    total={fetchCollegiatsData?.data?.data?.total}
                                    handleFormChange={handleFormChange}
                                    formValuesSearch={formValuesSearch}
                                    perPage={fetchCollegiatsData?.data?.data?.per_page}
                                />
                            </>
                            :
                            <>
                            <h5 className='my-0 py-5 text-center'>No es van trobar resultats</h5>
                            </>
                }

                <Modal show={showHistorialUsuario} onHide={handleCloseHistorialUsuario} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title className='h5'>Històric de les relacions amb els Col·legis</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-4'>
                        {
                            fetchHistorialData?.loading && fetchIngenieroData?.loading ?
                                <Loader /> :
                                <>
                                    <div className="d-flex justify-content-center mb-4 mt-2">
                                        <h5 className="me-3">{fetchIngenieroData?.data?.data?.nombre} {fetchIngenieroData?.data?.data?.apellidos}</h5>
                                    </div>

                                    {fetchIngenieroData?.data?.data?.historico_colegiaciones ?
                                        <Table bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>Núm. Col·legiat</th>
                                                    <th>Col·legi</th>
                                                    <th>Estat</th>
                                                    <th>Data estat</th>
                                                    {perfil.id === 'administrador' ? <th>Actualizat per</th> : <th>Observacions</th>}
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {fetchIngenieroData?.data?.data?.historico_colegiaciones?.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.num_colegiado}</td>
                                                            <td>{item.colegio.nombre}</td>
                                                            <td>{item.estado_colegiacion?.nombre}</td>
                                                            <td className='text-nowrap'>{item.fecha_estado}</td>
                                                            <td>{perfil.id === 'administrador' ? item.registrado_por?.email : item.observaciones}</td>
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </Table>
                                        : <h5 className='my-0 py-5 text-center'>No es van trobar resultats</h5>
                                    }
                                </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseHistorialUsuario}>
                            Sortir
                        </Button>
                    </Modal.Footer>
                </Modal>

                <ModalDarDeBaja
                    showModalDarDeBaja={showModalDarDeBaja}
                    handleCloseModalDarDeBaja={handleCloseModalDarDeBaja}
                    handleShowModalDarDeBaja={handleShowModalDarDeBaja}
                    fetchParametersData={fetchParametersData}
                    formValuesDarDeBaja={formValuesDarDeBaja}
                    handleInputChangeDarDeBaja={handleInputChangeDarDeBaja}
                    handleFormChangeDarDeBaja={handleFormChangeDarDeBaja}
                    resetFormValuesDarDeBaja={resetFormValuesDarDeBaja}
                    fetchDarDeBaja={fetchDarDeBaja}
                    fetchDarDeBajaData={fetchDarDeBajaData}
                    formValuesSearch={formValuesSearch}
                    fetchCollegiats={fetchCollegiats}
                    valuesValidDarDeBaja={valuesValidDarDeBaja}
                    handleVerifiedValuesDarDeBaja={handleVerifiedValuesDarDeBaja}
                    handleAreAllValuesCorrectDarDeBaja={handleAreAllValuesCorrectDarDeBaja}
                />

                <ModalEditarColegiado
                    showModalEditarColegiado={showModalEditarColegiado}
                    setShowModalEditarColegiado={setShowModalEditarColegiado}
                    handleCloseModalEditarColegiado={handleCloseModalEditarColegiado}
                    formValuesEditarColegiado={formValuesEditarColegiado}
                    handleInputChangeEditarColegiado={handleInputChangeEditarColegiado}
                    handleFormChangeEditarColegiado={handleFormChangeEditarColegiado}
                    resetFormValuesEditarColegiado={resetFormValuesEditarColegiado}
                    fetchEditarColegiado={fetchEditarColegiado}
                    fetchEditarColegiadoData={fetchEditarColegiadoData}
                    fetchParametersData={fetchParametersData}
                    fetchMapaColegios={fetchMapaColegios}
                    fetchMapaColegiosData={fetchMapaColegiosData}
                    fetchCollegiats={fetchCollegiats}
                    formValuesSearch={formValuesSearch}
                    valuesValidEditarColegiado={valuesValidEditarColegiado}
                    handleVerifiedValuesEditarColegiado={handleVerifiedValuesEditarColegiado}
                    handleAreAllValuesCorrectEditarColegiado={handleAreAllValuesCorrectEditarColegiado}
                />

                <ModalDarDeAlta
                    showModalDarDeAlta={showModalDarDeAlta}
                    setShowModalDarDeAlta={setShowModalDarDeAlta}
                    handleCloseModalDarDeAlta={handleCloseModalDarDeAlta}
                    fetchParametersData={fetchParametersData}
                    formValuesDarDeAlta={formValuesDarDeAlta}
                    handleInputChangeDarDeAlta={handleInputChangeDarDeAlta}
                    handleFormChangeDarDeAlta={handleFormChangeDarDeAlta}
                    resetFormValuesDarDeAlta={resetFormValuesDarDeAlta}
                    fetchMapaColegiosData={fetchMapaColegiosData}
                    fetchMapaColegios={fetchMapaColegios}

                    existeNIF={existeNIF}
                    setExisteNIF={setExisteNIF}
                    existeNIFColegio={existeNIFColegio}
                    setExisteNIFColegio={setExisteNIFColegio}
                    fetchDarDeAltaExisteNIF={fetchDarDeAltaExisteNIF}
                    fetchDarDeAltaExisteNIFData={fetchDarDeAltaExisteNIFData}
                    fetchDarDeAltaExisteNIFColegio={fetchDarDeAltaExisteNIFColegio}
                    fetchDarDeAltaExisteNIFColegioData={fetchDarDeAltaExisteNIFColegioData}
                    fetchDarDeAltaCrearIngeniero={fetchDarDeAltaCrearIngeniero}
                    fetchDarDeAltaCrearIngenieroData={fetchDarDeAltaCrearIngenieroData}
                    fetchDarDeAltaCrearColegiado={fetchDarDeAltaCrearColegiado}
                    fetchDarDeAltaCrearColegiadoData={fetchDarDeAltaCrearColegiadoData}
                    colegio={colegio}
                    existeNIFColegioBaja={existeNIFColegioBaja}
                    setExisteNIFColegioBaja={setExisteNIFColegioBaja}
                    formValuesSearch={formValuesSearch}
                    fetchCollegiats={fetchCollegiats}

                    valuesValidNIF={valuesValidNIF}
                    handleVerifiedValuesNIF={handleVerifiedValuesNIF}
                    handleAreAllValuesCorrectNIF={handleAreAllValuesCorrectNIF}

                    valuesValidNoExisteNIF={valuesValidNoExisteNIF}
                    handleVerifiedValuesNoExisteNIF={handleVerifiedValuesNoExisteNIF}
                    handleAreAllValuesCorrectNoExisteNIF={handleAreAllValuesCorrectNoExisteNIF}

                    valuesValidActivar={valuesValidActivar}
                    handleVerifiedValuesActivar={handleVerifiedValuesActivar}
                    handleAreAllValuesCorrectActivar={handleAreAllValuesCorrectActivar}

                    valuesValidCrearColegiado={valuesValidCrearColegiado}
                    handleVerifiedValuesCrearColegiado={handleVerifiedValuesCrearColegiado}
                    handleAreAllValuesCorrectCrearColegiado={handleAreAllValuesCorrectCrearColegiado}
                />

            </div>

        </div>
    )
}
