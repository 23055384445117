import React, {useEffect} from 'react'
import { images } from '../../helpers/images';

import './CarouselImages.css'
import $ from 'jquery'; 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


export const CarouselImages = () => {

    useEffect(() => {
        // OwlCarousel.trigger('refresh.owl.carousel');
        // console.log(document.getElementById('owl-login'))
        // document.getElementById('owl-login').trigger('refresh.owl.carousel');
        
    }, [])
    console.log('wp', images('./col_1.jpg'))
    
    const events = {
        onInitialized: function(event) {
            var $owl = $('.owl-carousel').owlCarousel({
                items: 1,
                loop: true
            });

            $owl.trigger('refresh.owl.carousel');
        }
    };

    return (
        <>
            <OwlCarousel id='owl-login' className='owl-theme' 
                loop items={1} loadedClass={'d-block'} 
                 autoplay autoplayTimeout={3000} stagePadding animateOut={'fadeOut'} margin={10} events={events} >
                <div className="container-image item">
                    <div className="container-image-text">
                        <h4>ENGINYERS BCN</h4>
                        <p>Col·legi d'Enginyers Graduats i Enginyers Tècnics Industrials de Barcelona</p>
                    </div>
                    <img src={images('./col_1.jpg').default ? images('./col_1.jpg').default : images('./col_1.jpg') } className="card-img" alt="ENGINYERS BCN" />
                </div>
                <div className="container-image item">
                    <div className="container-image-text">
                        <h4>ENGINYERS GIRONA</h4>
                        <p>Col·legi d'Enginyers Graduats i Enginyers Tècnics Industrials de Girona</p>
                    </div>
                    <img src={images('./col_2.jpg').default ? images('./col_2.jpg').default : images('./col_2.jpg')} className="card-img" alt="ENGINYERS GIRONA" />
                </div>
                <div className="container-image item">
                    <div className="container-image-text">
                        <h4>ENGINYERS LLEIDA</h4>
                        <p>Col·legi d'Enginyers Graduats i Enginyers Tècnics Industrials de Lleida</p>
                    </div>
                    <img src={images('./col_3.jpg').default ? images('./col_3.jpg').default : images('./col_3.jpg')} className="card-img" alt="ENGINYERS LLEIDA" />
                </div>
                <div className="container-image item">
                    <div className="container-image-text">
                        <h4>ENGINYERS MANRESA</h4>
                        <p>Col·legi d'Enginyers Graduats i Enginyers Tècnics Industrials de Manresa</p>
                    </div>
                    <img src={images('./col_4.jpg').default ? images('./col_4.jpg').default : images('./col_4.jpg')} className="card-img" alt="ENGINYERS MANRESA" />
                </div>
                <div className="container-image item">
                    <div className="container-image-text">
                        <h4>ENGINYERS TARRAGONA</h4>
                        <p>Col·legi d'Enginyers Graduats i Enginyers Tècnics Industrials de Tarragona</p>
                    </div>
                    <img src={images('./col_5.jpg').default ? images('./col_5.jpg').default : images('./col_5.jpg')} className="card-img" alt="ENGINYERS TARRAGONA" />
                </div>
                <div className="container-image item">
                    <div className="container-image-text">
                        <h4>ENGINYERS VILANOVA I LA GELTRÚ</h4>
                        <p>Col·legi d'Enginyers Graduats i Enginyers Tècnics Industrials de Vilanova i la Geltrú</p>
                    </div>
                    <img src={images('./col_6.jpg').default ? images('./col_6.jpg').default : images('./col_6.jpg')} className="card-img" alt="ENGINYERS VILANOVA I LA GELTRÚ" />
                </div>

            </OwlCarousel>
        </>
    )
}